import axios from "axios";
import swal from "sweetalert";
import { loginConfirmedAction, Logout } from "../store/actions/AuthActions";

// Get API base URL from environment or use default
const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:8083/api/v1";
console.log('API URL:', BASE_URL);

export function signUp(email, password) {
  //axios call
  const postData = {
    email,
    password,
    returnSecureToken: true,
  };
  return axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    postData
  );
}

export async function login(email, password) {
  const postData = {
    email,
    password,
  };

  // Fix the double slash in the route
  const loginEndpoint = `${BASE_URL}/admin/login`;
  console.log(`Attempting login to ${loginEndpoint} with:`, { email });
  
  try {
    // Add a timeout to prevent indefinite waiting - increased to 30 seconds
    const response = await axios.post(
      loginEndpoint,
      postData,
      { 
        timeout: 30000,
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      }
    );
    
    console.log("Login response received:", response.status, response.statusText);
    console.log("Response data:", response.data);
    
    return response;
  } catch (error) {
    console.error("Login failed", error);
    console.error("Login error details:", {
      message: error.message,
      status: error.response?.status,
      statusText: error.response?.statusText,
      data: error.response?.data,
      isAxiosError: error.isAxiosError,
      code: error.code
    });
    
    // Add more user-friendly error message
    if (error.code === 'ECONNABORTED') {
      error.message = 'Connection timeout. The server is taking too long to respond.';
    } else if (error.code === 'ERR_NETWORK') {
      error.message = 'Network error. Please check your connection and that the backend server is running.';
    }
    
    throw error;
  }
}

export function formatError(errorMessage) {
  if (errorMessage) {
    swal("Oops", errorMessage, "error");
    return errorMessage;
  }
  return "An unknown error occurred";
}

export function saveTokenInLocalStorage(tokenDetails) {
  if (!tokenDetails) {
    console.error("No token details provided to save");
    return;
  }
  
  try {
    localStorage.setItem("xguardToken", tokenDetails);
    console.log("Token saved successfully");
  } catch (error) {
    console.error("Error saving token:", error);
  }
}

export function runLogoutTimer(dispatch, timer, navigate) {
  setTimeout(() => {
    dispatch(Logout(navigate));
  }, timer);
}

export function checkAutoLogin(dispatch, navigate) {
  try {
    const token = localStorage.getItem("xguardToken");
    
    if (!token) {
      console.log("No token found");
      return false;
    }
    
    console.log("Token found, confirming login");
    dispatch(loginConfirmedAction(token));
    return true;
  } catch (error) {
    console.error("Error in checkAutoLogin:", error);
    return false;
  }
}

export function isLogin() {
  const token = localStorage.getItem("xguardToken");
  return !!token;
}

// Set up axios interceptor for auth headers
export function setupAxiosInterceptors() {
  axios.interceptors.request.use(
    config => {
      const token = localStorage.getItem('xguardToken');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    error => Promise.reject(error)
  );
  
  // Response interceptor to handle auth errors
  axios.interceptors.response.use(
    response => response,
    error => {
      if (error.response && error.response.status === 401) {
        // Unauthorized, clear token and redirect to login
        localStorage.removeItem('xguardToken');
        window.location.href = '/page-login';
      }
      return Promise.reject(error);
    }
  );
}

// Initialize the interceptors
setupAxiosInterceptors();
