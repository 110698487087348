import React from "react";
//import { useNavigate } from "react-router-dom";

import {
  formatError,
  login,
  runLogoutTimer,
  saveTokenInLocalStorage,
  signUp,
} from "../../services/AuthService";

export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";

export function signupAction(email, password, navigate) {
  return (dispatch) => {
    signUp(email, password)
      .then((response) => {
        saveTokenInLocalStorage(response.data);
        runLogoutTimer(
          dispatch,
          response.data.expiresIn * 1000
          //history,
        );
        dispatch(confirmedSignupAction(response.data));
        navigate("/dashboard");
        //history.push('/dashboard');
      })
      .catch((error) => {
        const errorMessage = formatError(error.response?.data || "Signup failed");
        dispatch(signupFailedAction(errorMessage));
      });
  };
}

export function Logout(navigate) {
  localStorage.removeItem("xguardToken");
  if (navigate) {
    navigate("/page-login");
  }
  //history.push('/login');

  return {
    type: LOGOUT_ACTION,
  };
}

export function loginAction(email, password, navigate, setLoader) {
  return (dispatch) => {
    setLoader(true);
    dispatch(loadingToggleAction(true));
    
    login(email, password)
      .then((response) => {
        console.log("Login successful", response);
        
        // Get token from response
        let token = null;
        let adminData = null;
        
        if (response?.data?.data?.token) {
          token = response.data.data.token;
          adminData = response.data.data;
        } else if (response?.data?.token) {
          token = response.data.token;
          adminData = response.data;
        } else if (typeof response?.data === 'string') {
          token = response.data;
        }
        
        // In development, use a fake token if none found
        if (!token && process.env.NODE_ENV === 'development') {
          token = "test-token-" + Date.now();
          console.warn("Using development test token");
        }
        
        if (!token) {
          console.error("No token found in response:", response.data);
          throw new Error("Authentication failed - no token received");
        }
        
        // Save token
        localStorage.setItem("xguardToken", token);
        
        // Log role information
        console.log("Admin data:", adminData);
        console.log("Role information:", adminData?.role);
        
        // Save admin role information if available
        if (adminData?.role) {
          localStorage.setItem("xguardAdminRole", JSON.stringify(adminData.role));
        } else {
          console.warn("No role information found in admin data");
          localStorage.removeItem("xguardAdminRole");
        }
        
        // Update redux state
        dispatch(loginConfirmedAction(token));
        
        // Reset loading
        if (typeof setLoader === 'function') {
          setLoader(false);
        }
        dispatch(loadingToggleAction(false));
        
        // Send admin data to callback if provided
        if (typeof setLoader === 'function' && typeof setLoader !== 'boolean') {
          setLoader(adminData);
        }
        
        // Navigate to dashboard
        if (navigate) {
          navigate("/dashboard");
        }
      })
      .catch((error) => {
        console.error("Login failed", error);
        
        // Get error message
        let errorMessage = "Login failed";
        if (error.response && error.response.data && error.response.data.message) {
          errorMessage = error.response.data.message;
        } else if (error.message) {
          errorMessage = error.message;
        }
        
        dispatch(loginFailedAction(errorMessage));
        
        // Reset loading state
        if (typeof setLoader === 'function') {
          setLoader(false);
        }
        
        dispatch(loadingToggleAction(false));
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
