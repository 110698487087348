import React, { useState, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import PageTitle from "../layouts/PageTitle";
import toast, { Toaster } from "react-hot-toast";
import { getTermsApi, getTermsServicesApi } from "../components/APIs/Api";
// import Button from "../../components/Button";
// import { sendPolicy } from "../../services/AuthService";
export default function Policies() {
  const [languageTextError, setLanguageTextError] = useState("");
  const [loader, setLoader] = useState(false);
  const [languageText, setLanguageText] = useState("");
  const handleChangeClinical = (content) => {
    setLanguageText(content);
    setLanguageTextError("");
  };
  // let editLanguage = "";
  useEffect(() => {
    async function handleGetTerms() {
      setLoader(true);
      try {
        const res = await getTermsApi("terms");
        setLanguageText(res?.data?.data[0]?.content);
      } catch (e) {
        toast.error(e?.response?.data?.message);
      } finally {
        setLoader(false);
      }
    }
    handleGetTerms();
  }, []);

  async function handleSubmitTerm(event) {
    event.preventDefault();

    if (languageText?.trim() === "") {
      setLanguageTextError("Terms and Conditions cannot be empty");
      return;
    }
    setLoader(true);
    const postData = {
      content: languageText,
      type: "terms",
    };
    try {
      const response = await getTermsServicesApi(postData);
      if (response?.status === 200) {
        setLoader(false);
        toast.success("Terms & Conditions Uploaded successfully");
      } else if (!response?.status === 200) {
        throw new Error("response.message");
      }
      setLanguageText("");
    } catch (error) {
      setLoader(false);
      toast.error(
        `Error: ${
          error?.response?.data?.data || error?.response?.data?.message
        }`
      );
    }
  }

  return (
    <div>
      <PageTitle activeMenu="Terms & Condition" motherMenu="Policies" />

      <Editor
        apiKey="puvcmpus1ty74pn05yqv6et6rdglxvo4q1lkxt0vh9yx2dc3"
        init={{
          height: 500,
          menubar: false,
          plugins: [
            "advlist autolink lists link image code charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code textcolor wordcount",
            "textarea",
            "textcolor",
            "forecolor backcolor",
          ],
          toolbar:
            "undo redo | formatselect | code |link | image | bold italic backcolor | alignleft aligncenter alignright alignjustify |  \n" +
            "bullist numlist outdent indent | textcolor | textarea | forecolor backcolor",
          content_style: "body { color: #000 }",
        }}
        onEditorChange={handleChangeClinical}
        name="prescription"
        value={languageText}
      />
      <span className="text-danger">{languageTextError}</span>

      <div className="d-flex justify-content-end mt-4">
        <button
          className={`btn btn-sm btn-primary ${loader ? "disabled" : ""}`}
          disabled={loader}
          style={
            loader
              ? { pointerEvents: "none", opacity: 0.5 }
              : { pointerEvents: "auto" }
          }
          onClick={(e) => {
            handleSubmitTerm(e);
          }}
        >
          {loader ? (
            <div
              className="d-flex align-items-center justify-content-center "
              style={{ gap: "5px" }}
            >
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Loading...
            </div>
          ) : (
            "Send"
          )}
        </button>
      </div>
    </div>
  );
}
