import React from "react";
import { useLocation } from "react-router-dom";
import ServiceInfo from "./ServiceInfo";
import ProfileCard from "../components/ui/ProfileCard";

function ServiceDetails() {
  const location = useLocation();
  const data = location.state;
  return (
    <div className="booking-page">
      <div className="left-panel">
        <ProfileCard data={data?.userId} title={"User"} />
        <ProfileCard data={data?.serviceUserId} title="Service User" />
      </div>
      <div className="right-panel">
        <ServiceInfo data={data} />
      </div>
    </div>
  );
}

export default ServiceDetails;
