import React from "react";
import PageTitle from "../layouts/PageTitle";

const TestPage = () => {
  return (
    <>
      <PageTitle activeMenu="Test Page" motherMenu="Testing" />
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">Test Page</h4>
          <p className="card-text">
            This is a simple test page to check if routing is working properly.
          </p>
        </div>
      </div>
    </>
  );
};

export default TestPage; 