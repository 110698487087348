import axios from "axios";

const SESSION_TIMEOUT = 60000000;

let sessionTimer;

const handleSessionExpiration = () => {
  console.log("Session expired, redirecting to login");
  localStorage.removeItem("xguardToken");
  window.location.replace("/login");
};

// Create axios instance with shared configuration
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || `http://localhost:8083/api/v1`,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("xguardToken");
    console.log("Making request to:", config.url);

    if (token) {
      config.headers["token"] = token;
    } else {
      console.warn("No token found in localStorage");
    }
    config.headers["Content-Type"] = "application/json";

    return config;
  },
  (error) => {
    console.error("Request interceptor error:", error);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    console.log("Response received:", {
      url: response.config.url,
      status: response.status,
      data: response.data
    });

    localStorage.setItem("sessionTimestamp", Date.now().toString());
    clearTimeout(sessionTimer);
    sessionTimer = setTimeout(handleSessionExpiration, SESSION_TIMEOUT);

    return response;
  },
  function (error) {
    console.error("Response error:", {
      url: error.config?.url,
      status: error.response?.status,
      data: error.response?.data,
      message: error.message
    });

    if (error.response?.status === 401) {
      clearTimeout(sessionTimer);
      handleSessionExpiration();
    }
    return Promise.reject(error);
  }
);
