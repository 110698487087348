import axios from "axios";
import { axiosInstance } from "../../../services/AxiosInstance";

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

// ===================getAPIS=============================
export async function dashboardApi() {
  const response = await axiosInstance.get("/admin/dashboard", {});
  return response;
}
export async function dashboardGraphApi() {
  const response = await axiosInstance.get("/admin/graph", {});
  return response;
}
export async function userManagementList(
  currentPage,
  itemsPerPage,
  search,
  kycType,
  userType,
  filterTypes
) {
  const response = await axiosInstance.get(
    `/admin/users?limit=${itemsPerPage}&page=${currentPage}&search=${search}&userType=${userType}&status=${kycType}&type=${filterTypes}`,
    {}
  );
  return response;
}

export async function getUserActionsList(type, currentPage, limit, search) {
  const response = await axiosInstance.get(
    `admin/listQueries?status=${type}&search=${search}&limit=${limit}&page=${currentPage}`
  );
  return response;
}
export async function blockUserApi(_id, type) {
  const postData = { ids: _id, type: type };
  const response = await axiosInstance.put(
    `/admin/enableDisableUser`,
    postData
  );
  return response;
}
export async function userKycApi(postData) {
  const response = await axiosInstance.put(`/admin/kycApproveReject`, postData);
  return response;
}
export async function userResolvedApi(id) {
  const response = await axiosInstance.put(
    `/admin/updateQueryStatus?_id=${id}`
  );
  return response;
}

export async function deleteUserApi(data) {
  const response = await axiosInstance.delete(
    `/admin/deleteUser?_id=${data?._id}`
  );
  return response;
}
//=======================pAYMENT API================
export async function getTransactionApi(currentPage, itemsPerPage) {
  const response = await axiosInstance.get(
    `/admin/transactions?limit=${itemsPerPage}&page=${currentPage}`,
    {}
  );
  return response;
}
// Category Management

export async function getCategoryApi(currentPage, itemsPerPage, search) {
  const response = await axiosInstance.get(
    `/admin/categoryList?limit=${itemsPerPage}&page=${currentPage}&search=${search}`,
    {}
  );
  return response;
}

export async function getFeeListApi(currentPage, itemsPerPage, search) {
  const response = await axiosInstance.get(
    `/admin/feeList?limit=${itemsPerPage}&page=${currentPage}&search=${search}`,
    {}
  );
  return response;
}
export async function getFeeHistoryApi(currentPage, itemsPerPage, name) {
  const response = await axiosInstance.get(
    `/admin/history?limit=${itemsPerPage}&page=${currentPage}&name=${name}`,
    {}
  );
  return response;
}
export async function getFeeCountApi(currentPage, itemsPerPage) {
  const response = await axiosInstance.get(
    `/admin/usersList?limit=${itemsPerPage}&page=${currentPage}`,
    {}
  );
  return response;
}
export async function getBookingListApi(currentPage, itemsPerPage, type) {
  const response = await axiosInstance.get(
    `/admin/bookingList?limit=${itemsPerPage}&page=${currentPage}&status=${type}`,
    {}
  );
  return response;
}
export async function getRatingApi(currentPage, itemsPerPage) {
  const response = await axiosInstance.get(
    `/admin/reviews?limit=${itemsPerPage}&page=${currentPage}`,
    {}
  );
  return response;
}
export async function addCategoryApi(data) {
  const response = await axiosInstance.post("/admin/category", data, {});
  return response;
}
export async function editCategoryApi(data) {
  const postData = { name: data.name, logo: data.logo, order: data.order };
  const response = await axiosInstance.put(
    `/admin/editCategory?_id=${data?._id}`,
    postData,
    {}
  );
  return response;
}

export async function editFeeApi(value, id) {
  const postData = { value };
  const response = await axiosInstance.put(
    `/admin/editFee?_id=${id}`,
    postData
  );
  return response;
}
export async function editProfileApi(value, id) {
  // const postData = { value };
  const response = await axiosInstance.put(`/admin/editUser?_id=${id}`, value);
  return response;
}
export async function sendNotificationApi(formData) {
  const postData = { message: formData.message, title: formData.title };
  const response = await axiosInstance.post(
    `/admin/notification?userType=${formData.type}`,
    postData
  );
  return response;
}
export async function deleteCategoryApi(data) {
  const response = await axiosInstance.delete(
    `/admin/deleteCategory?_id=${data?._id}`
  );
  return response;
}

export async function deleteReviewApi(data) {
  const response = await axiosInstance.delete(
    `/admin/deleteReview?_id=${data?._id}`
  );
  return response;
}
export async function getTermsServicesApi(data) {
  const response = await axiosInstance.post("admin/content", data);
  return response;
}
export async function getTermsApi(type) {
  const response = await axiosInstance.get(`admin/contentList?type=${type}`);
  return response;
}
export async function getViewUser(id) {
  const response = await axiosInstance.get(`/admin/viewUser?_id=${id?.id}`);
  return response;
}
//======================BADGES API==========================================
export async function getBadgesApi(currentPage, itemsPerPage, search) {
  const response = await axiosInstance.get(
    `/admin/skillList?limit=${itemsPerPage}&page=${currentPage}&search=${search}`,
    {}
  );
  return response;
}
export async function addBadgesApi(data) {
  const response = await axiosInstance.post("/admin/skill", data, {});
  return response;
}
export async function updateBadgesApi(data) {
  const postData = { name: data.name, logo: data.logo };
  const response = await axiosInstance.put(
    `/admin/editSkill?_id=${data?._id}`,
    postData,
    {}
  );
  return response;
}
export async function deleteBadgeApi(data) {
  const response = await axiosInstance.delete(
    `/admin/deleteSkill?_id=${data?._id}`
  );
  return response;
}

export async function updateCategoryOrderApi(data) {
  const response = await axiosInstance.put(
    `/admin/updateCategoryOrder`,
    data,
    {}
  );
  return response;
}

export async function toggleCategoryVisibilityApi(categoryId, isVisible) {
  try {
    console.log("Sending toggle request:", { categoryId, isVisible });
    const response = await axiosInstance.put(
      `/admin/toggleCategoryVisibility?_id=${categoryId}`,
      { isVisible: Boolean(isVisible) }
    );
    console.log("Toggle response:", response.data);
    return response;
  } catch (error) {
    console.error("API Error:", error);
    throw error;
  }
}
