import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  Table,
  Col,
} from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Lottie from "lottie-react";
import animationData from "..//../images/loding.json";
import moment from "moment";
import Filter from "../components/Filter/Filter";
import Pagination from "../components/Common/Pagination";
import CustomTooltip from "../components/Tooltip";
import toast from "react-hot-toast";
import ConfirmationModal from "../modal/ConfirmationModal";
import AddRoleModal from "../modal/AddRoleModal";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function RoleManagement() {
  const [loader, setLoader] = useState(true);
  const [search, setSearch] = useState("");
  const [rolesList, setRolesList] = useState({ data: { data: [], totalRecords: 0 } });
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedRole, setSelectedRole] = useState("");
  const [addRoleModal, setAddRoleModal] = useState(false);
  const [editRoleModal, setEditRoleModal] = useState(false);
  const [deleteRoleModal, setDeleteRoleModal] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Get API url from environment or use default
  const baseUrl = process.env.REACT_APP_API_URL || "http://localhost:8083/api/v1";

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const handleSearch = useCallback(
    debounce((value) => {
      setSearch(value);
      setCurrentPage(0);
    }, 600),
    []
  );

  // Fetch roles
  async function fetchRoles() {
    setLoader(true);
    try {
      const token = localStorage.getItem("xguardToken");
      if (!token) {
        setError("You must be logged in to view roles");
        // Redirect to login page if no token
        setTimeout(() => navigate("/page-login"), 3000);
        return;
      }
      
      console.log(`Attempting to fetch roles from: ${baseUrl}/admin/roles`);
      console.log("Using authorization token:", token.substring(0, 10) + "...");
      console.log("Current role info in localStorage:", localStorage.getItem("xguardAdminRole"));
      
      try {
        const response = await axios.get(`${baseUrl}/admin/roles`, {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
            "Accept": "application/json"
          },
          params: {
            page: currentPage,
            limit: itemsPerPage,
            search: search || undefined, // Only send if not empty
          },
          timeout: 15000 // 15 second timeout
        });
        
        console.log("Roles API response:", response.status, response.statusText);
        console.log("Roles data:", response.data);
        
        if (response.data && response.data.data && response.data.data.roles) {
          // Correctly handle the response format from the backend
          setRolesList({
            data: {
              data: response.data.data.roles,
              totalRecords: response.data.data.roles.length
            }
          });
        } else {
          console.warn("Unexpected response format:", response.data);
          setRolesList({ data: { data: [], totalRecords: 0 } });
          setError("Received unexpected data format from server");
        }
      } catch (err) {
        console.error("Error fetching roles:", err);
        console.error("Error details:", {
          status: err.response?.status,
          statusText: err.response?.statusText,
          data: err.response?.data,
          message: err.message
        });
        
        // If there's no response at all, try checking if the backend is up
        if (!err.response) {
          try {
            // Try checking the health endpoint
            const healthResponse = await axios.get(`${baseUrl}/admin/health`, { timeout: 5000 });
            console.log("Health check response:", healthResponse.status, healthResponse.data);
            
            if (healthResponse.status === 200) {
              setError("Backend server is available but you may not have permission to access roles.");
            } else {
              setError("Backend server is responding, but may have issues.");
            }
          } catch (healthErr) {
            console.error("Health check failed:", healthErr);
            setError("Backend server is not responding. Please try again later.");
          }
        } else {
          setError(
            err.response?.data?.message || 
            "Failed to load roles. " + (err.response?.status === 403 ? 
              "You do not have permission to manage roles." : 
              "API may not be available.")
          );
        }
        
        toast.error(
          err?.response?.data?.message || 
          err?.response?.data?.data || 
          err?.message || 
          "Failed to load roles"
        );
        
        // Set empty data to prevent UI from breaking
        setRolesList({ data: { data: [], totalRecords: 0 } });
      }
    } finally {
      setLoader(false);
    }
  }

  // Create new role
  const createRole = async (roleData) => {
    try {
      const token = localStorage.getItem("xguardToken");
      console.log("Creating role with data:", roleData); // Log the data being sent
      const response = await axios.post(`${baseUrl}/admin/roles`, roleData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      console.log("Role creation response:", response);
      toast.success("Role created successfully");
      fetchRoles();
    } catch (err) {
      console.error("Error creating role:", err);
      console.error("Error response:", err.response?.data);
      
      // Display a more specific error message
      toast.error(
        err?.response?.data?.message || 
        err?.response?.data?.data ||
        "Failed to create role. Please check your permissions and try again."
      );
    }
  };

  // Update role
  const updateRole = async (roleData) => {
    try {
      const token = localStorage.getItem("xguardToken");
      await axios.put(`${baseUrl}/admin/roles`, roleData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      toast.success("Role updated successfully");
      fetchRoles();
    } catch (err) {
      console.error("Error updating role:", err);
      toast.error(
        err?.response?.data?.data
          ? err?.response?.data?.data
          : err?.response?.data?.message || "Failed to update role"
      );
    }
  };

  // Delete role
  const deleteRole = async (roleId) => {
    try {
      const token = localStorage.getItem("xguardToken");
      await axios.delete(`${baseUrl}/admin/roles/${roleId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success("Role deleted successfully");
      fetchRoles();
    } catch (err) {
      console.error("Error deleting role:", err);
      toast.error(
        err?.response?.data?.data
          ? err?.response?.data?.data
          : err?.response?.data?.message || "Failed to delete role"
      );
    }
  };

  // Handle form submission for create/edit
  const handleFormSubmit = (data) => {
    if (editRoleModal) {
      updateRole({ ...data, _id: selectedRole._id });
    } else {
      createRole(data);
    }
  };

  //=========pagination=============
  const totalItems = rolesList?.data?.totalRecords || 0;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  useEffect(() => {
    fetchRoles();
  }, [currentPage, itemsPerPage, search]);

  const handleDeleteConfirm = () => {
    if (selectedRole && selectedRole._id) {
      deleteRole(selectedRole._id);
      setDeleteRoleModal(false);
    }
  };

  return (
    <>
      <div>
        <PageTitle
          activeMenu="Role List"
          motherMenu="Role Management"
        />
        <div className="pb-4 d-flex justify-content-between w-100">
          <div style={{ width: "40%" }}>
            <Filter searchType="search" setSearch={handleSearch} />
          </div>
          <div>
            <button
              className="btn btn-primary btn"
              onClick={() => setAddRoleModal(true)}
            >
              Add Role
            </button>
          </div>
        </div>
        <Col>
          {loader ? (
            <div className="">
              <Lottie
                style={{ height: "200px", marginTop: "45px", padding: "30px" }}
                animationData={animationData}
              />
            </div>
          ) : error ? (
            <Card>
              <Card.Body>
                <div className="text-center p-4">
                  <div className="mb-3">
                    <i className="fa fa-exclamation-triangle text-warning" style={{ fontSize: "3rem" }}></i>
                  </div>
                  <h4>{error}</h4>
                  <p>Please check your connection and try again.</p>
                  
                  {/* Debug information */}
                  <div className="alert alert-info mt-3 text-left">
                    <h5>Debug Information</h5>
                    <p><strong>Token:</strong> {localStorage.getItem("xguardToken") ? "Present" : "Missing"}</p>
                    <p><strong>Role Info:</strong> {localStorage.getItem("xguardAdminRole") || "No role information saved"}</p>
                    
                    {/* Parse and display role details if available */}
                    {localStorage.getItem("xguardAdminRole") && (
                      <div>
                        <h6>Role Details:</h6>
                        <pre style={{ maxHeight: "200px", overflow: "auto" }}>
                          {(() => {
                            try {
                              const roleData = JSON.parse(localStorage.getItem("xguardAdminRole"));
                              return JSON.stringify(roleData, null, 2);
                            } catch (e) {
                              return "Error parsing role data: " + e.message;
                            }
                          })()}
                        </pre>
                      </div>
                    )}
                    
                    <p>Make sure your account has the proper MANAGE_ROLES permission.</p>
                  </div>
                  
                  <button className="btn btn-primary mt-3" onClick={fetchRoles}>
                    Try Again
                  </button>
                </div>
              </Card.Body>
            </Card>
          ) : (
            <Card>
              <Card.Body>
                <Table responsive>
                  <thead style={{ color: "black" }}>
                    <tr>
                      <th>
                        <strong>NAME</strong>
                      </th>
                      <th>
                        <strong>DESCRIPTION</strong>
                      </th>
                      <th>
                        <strong>STATUS</strong>
                      </th>
                      <th>
                        <strong>CREATED AT</strong>
                      </th>
                      <th className="text-center">
                        <strong>ACTION</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {rolesList?.data?.data?.map((role, i) => (
                      <tr key={role?._id || i}>
                        <td>{role?.name || "N/A"}</td>
                        <td>
                          {role?.description?.length > 30
                            ? role?.description.slice(0, 30) + "..."
                            : role?.description || "N/A"}
                        </td>
                        <td>
                          <span className={`badge badge-${role?.isActive ? "success" : "danger"}`}>
                            {role?.isActive ? "Active" : "Inactive"}
                          </span>
                        </td>
                        <td>{role?.createdAt ? moment(role.createdAt).format("ll") : "N/A"}</td>
                        <td className="text-center">
                          <div className="display-flex gap">
                            <CustomTooltip content={"Delete"}>
                              <div
                                className="btn btn-danger btn-xs sharp hover-item-transition display-flex"
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                }}
                                onClick={() => {
                                  setSelectedRole(role);
                                  setDeleteRoleModal(true);
                                }}
                              >
                                <i className="fa fa-trash"></i>
                              </div>
                            </CustomTooltip>
                            <CustomTooltip content={"Edit"}>
                              <div
                                className="btn btn-xs sharp hover-item-transition display-flex"
                                style={{
                                  backgroundColor: "#2f4cdd",
                                  color: "white",
                                }}
                                onClick={() => {
                                  setSelectedRole(role);
                                  setEditRoleModal(true);
                                }}
                              >
                                <i className="fas fa-pencil-alt"></i>
                              </div>
                            </CustomTooltip>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {(!rolesList?.data?.data || rolesList?.data?.data?.length === 0) && (
                  <div className="justify-content-center d-flex my-5">
                    No roles found!
                  </div>
                )}
                {rolesList?.data?.data?.length > 0 && (
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="dataTables_info">
                      Total roles{" "}
                      <span
                        style={{
                          color: "rgb(243, 108, 3)",
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                        {totalItems}
                      </span>
                    </div>
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: "10px" }}
                    >
                      <Pagination
                        pageCount={totalPages}
                        pageValue={currentPage}
                        setPage={setCurrentPage}
                      />
                    </div>
                  </div>
                )}
              </Card.Body>
            </Card>
          )}
        </Col>
      </div>

      {/* Delete Confirmation Modal */}
      {deleteRoleModal && (
        <ConfirmationModal
          title="Are you sure?"
          description="Do you really want to delete this role? This process cannot be undone."
          icon="fa-exclamation-triangle text-danger"
          show={deleteRoleModal}
          onConfirm={handleDeleteConfirm}
          onCancel={() => setDeleteRoleModal(false)}
        />
      )}

      {/* Add Role Modal */}
      <AddRoleModal 
        show={addRoleModal}
        onHide={() => setAddRoleModal(false)}
        onSubmit={handleFormSubmit}
        editMode={false}
      />

      {/* Edit Role Modal */}
      <AddRoleModal 
        show={editRoleModal}
        onHide={() => setEditRoleModal(false)}
        onSubmit={handleFormSubmit}
        editMode={true}
        initialData={selectedRole}
      />
    </>
  );
} 