import React, { useState, useEffect } from "react";
import "./UserDetails.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import S3Image from "../components/S3Image";
import moment from "moment";
import ImageModal from "../modal/imageModal";
import toast from "react-hot-toast";
import { axiosInstance } from "../../services/AxiosInstance";
import { getViewUser } from "../components/APIs/Api";

function UserDetails() {
  const [activeTab, setActiveTab] = useState("Documents");
  const location = useLocation();
  const userData = location?.state;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [licenseData, setLicenseData] = useState({
    certificationNumber: userData?.documents?.certificationNumber || "",
    officialBatchNumber: userData?.documents?.officialBatchNumber || "",
    securityGuardCertNumber: userData?.documents?.securityGuardCertNumber || "",
    gunLicenceNumber: userData?.skills?.gunLicenceNumber || ""
  });
  
  // Get the previous page from location state or session storage as fallback
  const previousPage = location.state?.previousPage !== undefined 
    ? location.state.previousPage 
    : parseInt(sessionStorage.getItem('userListPage') || '0');
  
  // Save page number to session storage as backup
  useEffect(() => {
    if (location.state?.previousPage !== undefined) {
      sessionStorage.setItem('userListPage', location.state.previousPage.toString());
    }
  }, [location.state?.previousPage]);
  
  const handleInputChange = (field, value) => {
    setLicenseData(prev => ({
      ...prev,
      [field]: value
    }));
  };
  
  const updateLicenseData = async (field) => {
    if (!userData || !userData._id) {
      toast.error("User data is not available");
      return;
    }
    
    try {
      setIsLoading(true);
      if (!licenseData[field]) {
        toast.error(`Please enter ${field}`);
        setIsLoading(false);
        return;
      }
      
      const endpoint = `/api/v1/admin/update-user-document/${userData?._id}`;
      let updateData = {};
      
      // Determine which field to update and structure the data accordingly
      switch(field) {
        case 'certificationNumber':
          updateData = { 
            documents: {
              ...userData.documents,
              certificationNumber: licenseData.certificationNumber
            }
          };
          break;
        case 'officialBatchNumber':
          updateData = { 
            documents: {
              ...userData.documents,
              officialBatchNumber: licenseData.officialBatchNumber
            }
          };
          break;
        case 'securityGuardCertNumber':
          updateData = { 
            documents: {
              ...userData.documents,
              securityGuardCertNumber: licenseData.securityGuardCertNumber
            }
          };
          break;
        case 'gunLicenceNumber':
          updateData = { 
            skills: {
              ...userData.skills,
              gunLicenceNumber: licenseData.gunLicenceNumber
            }
          };
          break;
        default:
          toast.error('Invalid field to update');
          setIsLoading(false);
          return;
      }
      
      const response = await axiosInstance.put(endpoint, updateData);
      
      if (response.status === 200) {
        toast.success(`${field} updated successfully`);
        // Update the local user data to reflect the change
        setLicenseData(prev => ({
          ...prev,
          [field]: licenseData[field]
        }));
      } else {
        toast.error('Failed to update data');
      }
    } catch (error) {
      console.error("Error updating license data:", error);
      toast.error(error.response?.data?.message || 'Failed to update data');
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  
  const handleGoBack = () => {
    // Save all filter/search parameters to ensure exact same list is shown
    const filterParams = JSON.parse(sessionStorage.getItem('userListParams') || '{}');
    
    // Navigate to the user management page with pagination state
    navigate("/user-management", { 
      state: { 
        currentPage: previousPage,
        preserveScroll: true,
        fromUserDetails: true,
        ...filterParams
      }
    });
  };
  
  const getActiveDays = (week) => {
    if (!week) return "Not specified";
    return Object.entries(week)
      .filter(([day, isActive]) => isActive)
      .map(([day]) => day.charAt(0).toUpperCase() + day.slice(1))
      .join(", ") || "None";
  };

  // Safely destructure profile data with default values
  const { 
    from = "Not specified", 
    to = "Not specified", 
    hourlyRate = "Not specified", 
    uniformDescription = "Not specified", 
    week = {} 
  } = userData?.profile || {};
  
  const activeDays = getActiveDays(week);

  const [selectedImage, setSelectedImage] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setShowImageModal(true);
  };

  return (
    <div
      className="user-details"
      style={{ background: userData?.userType !== "civilian" ? "#fff" : "" }}
    >
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", width: "100%" }}>
        <h3>User Details</h3>
        <button 
          className="back-button"
          onClick={handleGoBack}
        >
          Back
        </button>
      </div>
      
      {userData?.userType === "civilian" ? (
        <div className="d-flex w-100" style={{ gap: "20px" }}>
          {/* <div>
            <S3Image imageKey={userData?.image} style={"user-image"} />
          </div>
          <div className="user-info">
            <h2>{userData?.name}</h2>
            <p className="user-job-title">{userData?.userType}</p>
            <p className="user-location m-0">New York, NY</p>
            <div className="user-actions">
              <p className="m-0">
                <strong>KYC :</strong>{" "}
                <span>
                  {" "}
                  {userData?.kycStatus === "pending" ? (
                    <span className="badge-info light badge">Pending</span>
                  ) : userData?.kycStatus === "rejected" ? (
                    <span className="badge-danger light badge">Suspended</span>
                  ) : (
                    <span className="badge-success light badge">Suspended</span>
                  )}
                </span>
              </p>
            </div>

            <div>
              <div className="tab-content">
                {activeTab === "Documents" ? (
                  <div className="document-section">
                    <h5>Documents</h5>
                    <div className="documents-images">
                      <div className="d-flex flex-column align-items-center">
                        <S3Image
                          imageKey={userData?.documents?.certificationDoc}
                        />
                        <strong className="m-0">Certification</strong>
                        <span>{userData?.documents?.certificationNumber}</span>
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <S3Image
                          imageKey={userData?.documents?.drivingLicenceDoc}
                        />
                        <strong className="m-0">Driving License</strong>
                        <span>{userData?.documents?.drivingLicenceNumber}</span>
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <S3Image
                          imageKey={userData?.documents?.officialBatchDoc}
                        />
                        <strong className="m-0">Official Batch</strong>
                        <span>{userData?.documents?.officialBatchNumber}</span>
                      </div>
                      <div className="d-flex flex-column align-items-center">
                        <S3Image
                          imageKey={
                            userData?.documents?.securityGuaradClarification
                          }
                        />
                        <strong className="m-0">Guard Certification</strong>
                      </div>
                    </div>
                    <h5>Skills</h5>
                    <div className="documents-images">
                      <div className="">
                        <S3Image imageKey={userData?.skills?.gunLicenceDoc} />
                        <strong className="m-0 text-center">Gun License</strong>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="user-contact">
                    <div className="">
                      <h5>Profile</h5>
                      <p className="mt-3">
                        <strong>Start Time:</strong>{" "}
                        <span>{userData?.profile?.from || "N/A"}</span>
                      </p>
                      <p>
                        <strong>End Time:</strong>{" "}
                        <span>{userData?.profile?.to || "N/A"}</span>
                      </p>
                      <p>
                        <strong>Hourly Rate:</strong>{" "}
                        <span>{userData?.profile?.hourlyRate}</span>
                      </p>
                      <p>
                        <strong>Average Time:</strong>{" "}
                        <span>
                          {userData?.profile?.averageResponseTime || "N/A"}
                        </span>
                      </p>
                    </div>
                    <h5>Contact Information</h5>
                    <p>
                      <strong>Phone:</strong>{" "}
                      <strong>
                        {userData?.countryCode} - {userData?.number}
                      </strong>
                    </p>
                    <p>
                      <strong>Address:</strong>{" "}
                      <strong>
                        {userData?.profile?.address || "N/A"}
                      </strong>
                    </p>
                    <p>
                      <strong>Email:</strong> <strong>{userData?.email}</strong>
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div> */}

          <div className="col-xl-4 col-lg-6 ">
            <div className="card h-auto">
              <div className="card-body text-center">
                <S3Image
                  imageKey={userData?.image}
                  style={"rounded-circle mb-4 booking-profile-img"}
                />
                <h4 className="m-0 text-black font-w600">{userData?.name}</h4>
                <small>{userData?.email}</small>
                <br />
                <small>
                  {userData?.countryCode} {userData?.phoneNumber}
                </small>
              </div>
            </div>
          </div>

          <div className="user-contact-civilian card ">
            <div className="d-flex justify-content-between">
              <h2 className="m-0">{userData?.name}</h2>
              <p className="m-0">{moment(userData?.cratedAt).format("ll")}</p>
            </div>
            <p className="user-job-title m-0">{userData?.userType}</p>
            {/* <p className="user-location m-0">New York, NY</p> */}
            <div className="mb-3">
              <p className="m-0">
                <strong>KYC :</strong>{" "}
                <span>
                  {" "}
                  {userData?.kycStatus === "pending" ? (
                    <span className="badge-info light badge">Pending</span>
                  ) : userData?.kycStatus === "rejected" ? (
                    <span className="badge-danger light badge">Suspended</span>
                  ) : userData?.kycStatus === "accepted" ? (
                    <span className="badge-success light badge">Accepted</span>
                  ) : (
                    <span className="badge-secondary light badge">-</span>
                  )}
                </span>
              </p>
            </div>
            {userData?.userType === "civilian" ? null : (
              <div className="section-first">
                <h4 className="font-w600 mt-1">Profile</h4>
                <p>
                  <strong>Start Time:</strong>{" "}
                  <span>{userData?.profile?.from || "N/A"}</span>
                </p>
                <p>
                  <strong>End Time:</strong>{" "}
                  <span>{userData?.profile?.to || "N/A"}</span>
                </p>
                <p>
                  <strong>Hourly Rate:</strong>{" "}
                  <span>{userData?.profile?.hourlyRate || "N/A"}</span>
                </p>
                <p>
                  <strong>Average Time:</strong>{" "}
                  <span>{userData?.profile?.averageResponseTime || "N/A"}</span>
                </p>
              </div>
            )}

            <div className="section-first">
              <h4 className="font-w600 mt-1">Profile</h4>
              <p>
                <strong>Start Time:</strong>{" "}
                <span>{userData?.profile?.from || "N/A"}</span>
              </p>
              <p>
                <strong>End Time:</strong>{" "}
                <span>{userData?.profile?.to || "N/A"}</span>
              </p>
              <p>
                <strong>Hourly Rate:</strong>{" "}
                <span>{userData?.profile?.hourlyRate}</span>
              </p>
              <p>
                <strong>Average Time:</strong>{" "}
                <span>{userData?.profile?.averageResponseTime || "N/A"}</span>
              </p>
            </div>
            <div className="section-first mt-3">
              <h4 className="font-w600 mt-1">Contact Information</h4>
              <p>
                <strong>Phone:</strong>{" "}
                <strong>
                  {userData?.countryCode} - {userData?.number}
                </strong>
              </p>
              <p>
                <strong>Address:</strong>{" "}
                <strong>{userData?.address?.line || "N/A"}</strong>
              </p>
              <p>
                <strong>Email:</strong> <strong>{userData?.email}</strong>
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="user-header ">
          <div>
            <S3Image imageKey={userData?.image} style={"user-image"} />
            <div className="agency-profile">
              <h5>Agency Profile</h5>
              <div className="work-item mt-4">
                <strong>Address</strong>
                <p>{userData?.agencyProfile?.address || "N/A"}</p>
              </div>
              <div className="work-item">
                <strong>Country</strong>
                <p>{userData?.agencyProfile?.country}</p>
              </div>
              <div className="work-item">
                <strong>Number of Employee</strong>
                <p>{userData?.agencyProfile?.numberOfEmp}</p>
              </div>
              <div className="work-item">
                <strong>Postal Code</strong>
                <p>{userData?.agencyProfile?.postalCode}</p>
              </div>
              <div className="work-item">
                <strong>Uniform Bio</strong>
                <p>{userData?.agencyProfile?.uniformDescription}</p>
              </div>
              <div className="work-item">
                <strong>Agency Bio / Guardian Bio</strong>
                <p>{userData?.agencyProfile?.agencyBio}</p>
              </div>
            </div>
          </div>
          <div className="user-info">
            <h2>{userData?.name}</h2>
            <p className="user-job-title">{userData?.userType}</p>
            {/* <p className="user-location m-0">
              {userData?.profile?.address || "N/A"}
            </p> */}
            <div className="user-actions">
              <p className="m-0">
                <strong>KYC :</strong>{" "}
                <span>
                  {" "}
                  {userData?.kycStatus === "pending" ? (
                    <span className="badge-info light badge">Pending</span>
                  ) : userData?.kycStatus === "rejected" ? (
                    <span className="badge-danger light badge">Rejected</span>
                  ) : (
                    <span className="badge-success light badge">Accepted</span>
                  )}
                </span>
              </p>
            </div>
            {/* <div className="user-actions">
              <p className="m-0">
                <strong>Code:</strong> <span>84947</span>
              </p>
            </div> */}
            <div>
              <div className="profile-tabs">
                <p
                  className={`tab-button ${
                    activeTab === "Documents" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Documents")}
                >
                  Documents
                </p>

                <p
                  className={`tab-button ${
                    activeTab === "About" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("About")}
                >
                  About
                </p>

                <p
                  className={`tab-button ${
                    activeTab === "Skills" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Skills")}
                >
                  Badges / Skills
                </p>
              </div>
              <div className="tab-content">
                <div className="tab-content">
                  {activeTab === "Documents" && (
                    <div className="document-section">
                      <h5>Documents</h5>
                      <div className="documents-images">
                        <div className="d-flex flex-column align-items-center">
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              handleImageClick(
                                userData?.documents?.certificationDoc
                              );
                            }}
                            className="cursor-pointer"
                          >
                            <S3Image
                              imageKey={userData?.documents?.certificationDoc}
                            />
                          </div>
                          <strong className="m-0">CCW License</strong>
                          <div className="input-group mt-1" style={{ width: "150px" }}>
                            <input 
                              type="text" 
                              className="form-control" 
                              placeholder="License Number" 
                              value={licenseData.certificationNumber}
                              onChange={(e) => handleInputChange('certificationNumber', e.target.value)}
                              onBlur={() => updateLicenseData('certificationNumber')}
                              disabled={isLoading}
                            />
                          </div>
                        </div>
                        <div className="d-flex flex-column align-items-center">
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              handleImageClick(
                                userData?.documents?.drivingLicenceDoc
                              );
                            }}
                            className="cursor-pointer"
                          >
                            <S3Image
                              imageKey={userData?.documents?.drivingLicenceDoc}
                            />
                          </div>
                          <strong className="m-0">Driving License</strong>
                          <span>
                            {userData?.documents?.drivingLicenceNumber}
                          </span>
                        </div>
                        <div className="d-flex flex-column align-items-center">
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              handleImageClick(
                                userData?.documents?.officialBatchDoc
                              );
                            }}
                            className="cursor-pointer"
                          >
                            <S3Image
                              imageKey={userData?.documents?.officialBatchDoc}
                            />
                          </div>

                          <strong className="m-0">Officer Badge</strong>
                          <div className="input-group mt-1" style={{ width: "150px" }}>
                            <input 
                              type="text" 
                              className="form-control" 
                              placeholder="Badge Number" 
                              value={licenseData.officialBatchNumber}
                              onChange={(e) => handleInputChange('officialBatchNumber', e.target.value)}
                              onBlur={() => updateLicenseData('officialBatchNumber')}
                              disabled={isLoading}
                            />
                          </div>
                        </div>
                        <div className="d-flex flex-column align-items-center">
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              handleImageClick(
                                userData?.documents?.securityGuaradClarification
                              );
                            }}
                            className="cursor-pointer"
                          >
                            <S3Image
                              imageKey={
                                userData?.documents?.securityGuaradClarification
                              }
                            />
                          </div>

                          <strong className="m-0">
                            Security Guard Certification
                          </strong>
                          <div className="input-group mt-1" style={{ width: "150px" }}>
                            <input 
                              type="text" 
                              className="form-control" 
                              placeholder="Certification Number" 
                              value={licenseData.securityGuardCertNumber}
                              onChange={(e) => handleInputChange('securityGuardCertNumber', e.target.value)}
                              onBlur={() => updateLicenseData('securityGuardCertNumber')}
                              disabled={isLoading}
                            />
                          </div>
                        </div>
                      </div>
                      <h5>Skills</h5>
                      <div className="documents-images">
                        <div className="">
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              handleImageClick(userData?.skills?.gunLicenceDoc);
                            }}
                            className="cursor-pointer"
                          >
                            <S3Image
                              imageKey={userData?.skills?.gunLicenceDoc}
                            />
                          </div>
                          <strong className="m-0 text-center">
                            FireArm Licence
                          </strong>
                          <div className="input-group mt-1" style={{ width: "150px", margin: "0 auto" }}>
                            <input 
                              type="text" 
                              className="form-control" 
                              placeholder="License Number" 
                              value={licenseData.gunLicenceNumber}
                              onChange={(e) => handleInputChange('gunLicenceNumber', e.target.value)}
                              onBlur={() => updateLicenseData('gunLicenceNumber')}
                              disabled={isLoading}
                            />
                          </div>
                        </div>
                      </div>

                      <h5>Category</h5>
                      <div className="d-flex flex-column flex-wrap gap-1">
                        {userData?.categoryId?.map((item, index) => {
                          return (
                            <div key={index}>
                              <strong className="m-0">{item?.name}</strong>
                            </div>
                          );
                        })}
                      </div>

                      <div className="d-flex flex-column mt-5 documents-images">
                        <strong className="mb-2">Insurance</strong>
                        <div className="d-flex gap-4">
                          <div className="text-center">
                            <span className="d-block mb-1">
                              General Liability InsuranceDoc
                            </span>
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                handleImageClick(
                                  userData?.documents
                                    ?.generalLiabilityInsuranceDoc
                                );
                              }}
                              className="cursor-pointer"
                            >
                              <S3Image
                                imageKey={
                                  userData?.documents
                                    ?.generalLiabilityInsuranceDoc
                                }
                                className="insurance-image"
                              />
                            </div>
                          </div>

                          <div className="text-center">
                            <span className="d-block mb-1">
                              Professional Liability InsuranceDoc
                            </span>
                            <div
                              onClick={(e) => {
                                e.stopPropagation();
                                handleImageClick(
                                  userData?.documents
                                    ?.professionalLiabilityInsuranceDoc
                                );
                              }}
                              className="cursor-pointer"
                            >
                              <S3Image
                                imageKey={
                                  userData?.documents
                                    ?.professionalLiabilityInsuranceDoc
                                }
                                className="insurance-image"
                              />
                            </div>
                          </div>

                          {}

                          {userData?.userType === "agency" && (
                            <div className="text-center">
                              <span className="d-block mb-1">Workers Comp</span>
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleImageClick(
                                    userData?.documents?.workersComp
                                  );
                                }}
                                className="cursor-pointer"
                              >
                                <S3Image
                                  imageKey={userData?.documents?.workersComp}
                                  className="insurance-image"
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {activeTab === "About" && (
                    <div className="user-contact">
                      <div className="">
                        <h5>Profile</h5>
                        <p>
                          <strong>Days availability:</strong>
                          <span>{activeDays}</span>
                        </p>

                        {userData?.userType === "userType" ? (
                          <p>
                            <strong>Timing:</strong>
                            <span>
                              {from} - {to}
                            </span>
                          </p>
                        ) : (
                          <p>
                            <strong>Timing:</strong>
                            <span>
                              {moment(
                                userData?.agencyProfile?.from,
                                "HH:mm"
                              ).format("hh:mm A")}{" "}
                              -
                              {moment(
                                userData?.agencyProfile?.to,
                                "HH:mm"
                              ).format("hh:mm A")}
                            </span>
                          </p>
                        )}

                        <p>
                          <strong>Uniform code:</strong>
                          <span>{uniformDescription || "N/A"}</span>
                        </p>
                        <p>
                          <strong>Hourly Rate:</strong>{" "}
                          <span>{userData?.profile?.hourlyRate}</span>
                        </p>
                        <p>
                          <strong>Average Time:</strong>{" "}
                          <span>
                            {userData?.profile?.averageResponseTime || "N/A"}
                          </span>
                        </p>
                        <p>
                          <strong>Security Qualification:</strong>{" "}
                          <span>
                            {userData?.skills?.qualification || "N/A"}
                          </span>
                        </p>
                        <p>
                          <strong>Language Known:</strong>{" "}
                          <span>{userData?.languages || "N/A"}</span>
                        </p>
                      </div>
                      <h5>Contact Information</h5>
                      <p>
                        <strong>Phone:</strong>{" "}
                        <strong>
                          {userData?.countryCode} - {userData?.number}
                        </strong>
                      </p>
                      <p>
                        <strong>Address:</strong>{" "}
                        <strong>
                          {userData?.agencyProfile?.address || "N/A"}
                        </strong>
                      </p>
                      <p>
                        <strong>Email:</strong>{" "}
                        <strong>{userData?.email}</strong>
                      </p>
                    </div>
                  )}

                  {activeTab === "Skills" &&
                  userData?.skills?.tacticalSkills?.length > 0 ? (
                    <div style={{ marginTop: "20px" }}>
                      <div className="skills-list">
                        {userData.skills.tacticalSkills.map((skill) => (
                          <div
                            key={skill._id}
                            className="d-flex align-items-center justify-content-between gap-2"
                          >
                            <strong>{skill.name}</strong>
                            <S3Image
                              imageKey={skill?.logo}
                              style="profile-img"
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    activeTab === "Skills" && (
                      <div style={{ marginTop: "20px" }}>
                        <p>No skills available</p>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {selectedImage && (
        <ImageModal
          show={showImageModal}
          onHide={() => setShowImageModal(false)}
          imageUrl={selectedImage}
        />
      )}
    </div>
  );
}

export default UserDetails;
