import React, { useState, useContext } from "react";

/// React router dom
import { Link } from "react-router-dom";

/// images
import logofinawtext from "../../../images/logofinawtext.png";
// import { ThemeContext } from "../../../context/ThemeContext";

export function NavMenuToggle() {
  setTimeout(() => {
    let mainwrapper = document.querySelector("#main-wrapper");
    if (mainwrapper.classList.contains("menu-toggle")) {
      mainwrapper.classList.remove("menu-toggle");
    } else {
      mainwrapper.classList.add("menu-toggle");
    }
  }, 200);
}

const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  // const { navigationHader, openMenuToggle, background } = useContext(
  //    ThemeContext
  // );
  return (
    <div className="nav-header">
      <Link to="/" className="brand-logo">
        <img className="logo-abbr" src={logofinawtext} alt="XGuard Logo" style={{ width: '45px' }} />
        <div className="brand-title text-hide-md" style={{ color: '#FF0000', fontWeight: 'bold', fontSize: '16px', marginLeft: '10px' }}>ADMIN PANEL</div>
      </Link>

      <div
        className="nav-control"
        onClick={() => {
          setToggle(!toggle);
          //openMenuToggle();
          NavMenuToggle();
        }}
      >
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
