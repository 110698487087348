import React, { useEffect, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { editProfileApi, getViewUser } from "../components/APIs/Api";
import { useParams } from "react-router-dom";
import { Formik, Field, Form as FormikForm, ErrorMessage } from "formik";
import * as Yup from "yup";
import S3Image from "../components/S3Image";
import AWS from "aws-sdk";

const VALIDATION_SCHEMA = Yup.object({
  name: Yup.string().required("Name is required."),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required."),
  phone: Yup.string().required("Phone is required."),
  userType: Yup.string().required("User Type is required."),
});

const EditProfile = ({ refresh }) => {
  const [loader, setLoader] = useState(false);
  const [profilePicFile, setProfilePicFile] = useState(null);
  const [coverPicFile, setCoverPicFile] = useState(null);
  const [data, setData] = useState(null);
  const [profilePicPreview, setProfilePicPreview] = useState(null);
  const [coverPicPreview, setCoverPicPreview] = useState(null);

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getUserData();
    }
  }, [id]);

  const getUserData = async () => {
    const params = { id };
    const res = await getViewUser(params);
    setData(res?.data);
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
      const file = files[0];
      if (name === "image") {
        setProfilePicFile(file);
        setProfilePicPreview(URL.createObjectURL(file));
      } else if (name === "coverImage") {
        setCoverPicFile(file);
        setCoverPicPreview(URL.createObjectURL(file));
      }
    }
  };

  const handleUpload = async (file) => {
    if (!file) {
      console.error("No file selected");
      return;
    }
    console.log({ file });

    const params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: file.name,
      Body: file,
    };

    try {
      const data = await s3.upload(params).promise();
      return data;
    } catch (error) {
      console.error("Upload failed:", error);
      throw error;
    }
  };
  const INITIAL_VALUES = data
    ? {
        userId: data?._id || "",
        name: data?.name || "",
        email: data?.email || "",
        phone: data?.number || "",
        userType: data?.userType || "",
        bio: data?.bio || "",
        image: data?.image || "",
        coverImage: data?.coverImage || "",
      }
    : {};

  const s3 = new AWS.S3();

  const handleSubmit = async (values) => {
    console.log({ values });
    setLoader(true);
    try {
      const updatedFormData = new FormData();
      updatedFormData.append("userId", values.userId);
      updatedFormData.append("name", values.name);
      updatedFormData.append("bio", values.bio);

      if (profilePicFile) {
        updatedFormData.append("image", handleUpload(profilePicFile));
      }

      if (coverPicFile) {
        updatedFormData.append("coverImage", handleUpload(coverPicFile));
      }

      const result = await editProfileApi(updatedFormData, data._id);
      toast.success("Profile updated successfully");
    } catch (e) {
      toast.error(e?.response?.data?.data || e?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  };

  if (!data) {
    return <Spinner animation="border" />;
  }

  return (
    <div>
      <h2>Edit Profile</h2>
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values }) => {
          return (
            <FormikForm>
              {/* Name Field */}
              <Form.Group className="mb-3" controlId="editProfileName">
                <Form.Label>Name</Form.Label>
                <Field
                  type="text"
                  placeholder="Enter name"
                  name="name"
                  className="form-control"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="invalid-feedback"
                />
              </Form.Group>

              {/* Email Field */}
              <Form.Group className="mb-3" controlId="editProfileEmail">
                <Form.Label>Email</Form.Label>
                <Field
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  className="form-control"
                  disabled
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="invalid-feedback"
                  disabled
                />
              </Form.Group>

              {/* Phone Field */}
              <Form.Group className="mb-3" controlId="editProfilePhone">
                <Form.Label>Phone</Form.Label>
                <Field
                  type="text"
                  placeholder="Enter phone"
                  name="phone"
                  className="form-control"
                  disabled
                />
                <ErrorMessage
                  name="phone"
                  component="div"
                  className="invalid-feedback"
                />
              </Form.Group>

              {/* User Type Field */}
              <Form.Group className="mb-3" controlId="editProfileUserType">
                <Form.Label>User Type</Form.Label>
                <Field
                  type="text"
                  placeholder="Enter user type"
                  name="userType"
                  className="form-control"
                />
                <ErrorMessage
                  name="userType"
                  component="div"
                  className="invalid-feedback"
                />
              </Form.Group>

              {/* Bio Field */}
              <Form.Group className="mb-3" controlId="editProfileBio">
                <Form.Label>Bio</Form.Label>
                <Field
                  as="textarea"
                  rows={3}
                  placeholder="Tell us about yourself"
                  name="bio"
                  className="form-control"
                />
              </Form.Group>

              <Form.Group
                className="mb-3 flex flex-direction-column"
                controlId="editProfileImage"
              >
                <Form.Label>Profile Picture</Form.Label>
                {profilePicPreview ? (
                  <div>
                    <img
                      src={profilePicPreview}
                      alt="Profile Preview"
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                ) : values.image ? (
                  <div>
                    <S3Image imageKey={values?.image} style="profile-img" />
                    <label
                      htmlFor="editProfileImageInput"
                      className="w-full h-24 flex items-center justify-center bg-gray-700 rounded-lg cursor-pointer hover:bg-gray-600 transition-all"
                    >
                      <span style={{ color: "red", marginLeft: "15px" }}>
                        Edit
                      </span>
                    </label>
                    <input
                      type="file"
                      id="editProfileImageInput"
                      name="image"
                      style={{ display: "none" }}
                      accept="image/*"
                      onChange={(e) => {
                        setFieldValue("image", e.currentTarget.files[0]);
                        handleFileChange(e);
                      }}
                    />
                  </div>
                ) : (
                  <input
                    type="file"
                    id="image"
                    name="image"
                    onChange={(e) => {
                      setFieldValue("image", e.currentTarget.files[0]);
                      handleFileChange(e);
                    }}
                  />
                )}
              </Form.Group>

              <Form.Group className="mb-3" controlId="formCover">
                <Form.Label>Cover Photo</Form.Label>
                {coverPicPreview ? (
                  <div>
                    <img
                      src={coverPicPreview}
                      alt="Cover Preview"
                      style={{
                        width: "100px",
                        height: "100px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                ) : values.coverImage ? (
                  <div>
                    <S3Image
                      imageKey={values?.coverImage}
                      style="profile-img"
                    />
                    <label htmlFor="coverImage">
                      <span
                        style={{
                          color: "red",
                          marginLeft: "15px",
                          cursor: "pointer",
                        }}
                      >
                        Edit
                      </span>
                    </label>

                    <input
                      type="file"
                      name="coverImage"
                      id="coverImage"
                      onChange={(e) => {
                        setFieldValue("coverImage", e.currentTarget.files[0]);
                        handleFileChange(e);
                      }}
                      style={{ display: "none" }}
                    />
                  </div>
                ) : (
                  <input
                    type="file"
                    name="coverImage"
                    onChange={(e) => {
                      setFieldValue("coverImage", e.currentTarget.files[0]);
                      handleFileChange(e);
                    }}
                  />
                )}
              </Form.Group>

              {/* Submit Button */}
              <Button
                variant="primary"
                type="submit"
                disabled={loader}
                className="w-100"
              >
                {loader ? (
                  <>
                    <Spinner
                      animation="border"
                      size="sm"
                      title="Updating..."
                      className="me-2"
                    />
                    Updating...
                  </>
                ) : (
                  "Update"
                )}
              </Button>
            </FormikForm>
          );
        }}
      </Formik>
    </div>
  );
};

export default EditProfile;
