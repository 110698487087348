import React, { Suspense, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes, Navigate } from "react-router-dom";
import { checkAutoLogin, isLogin } from "./admin/services/AuthService";
import "./App.css";
import "./admin/jsx/layouts/Common.css";
import "./admin/css/style.css";
import "./admin/css/Global.css";

// Direct imports
import Admin from "./admin/jsx/index";
import Login from "./admin/jsx/pages/Login";
import Registration from "./admin/jsx/pages/Registration";
import ForgotPassword from "./admin/jsx/pages/ForgotPassword";
import Error404 from "./admin/jsx/pages/Error404";

// Loading component
const Loader = () => (
  <div id="preloader">
    <div className="sk-three-bounce">
      <div className="sk-child sk-bounce1"></div>
      <div className="sk-child sk-bounce2"></div>
      <div className="sk-child sk-bounce3"></div>
    </div>
  </div>
);

function App() {
  const dispatch = useDispatch();
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    // Check authentication status when app loads
    const token = localStorage.getItem("xguardToken");
    console.log("Token check at App load:", token ? "Found" : "Not found");
    
    // Force completion of loading state after 1 second 
    // regardless of auth check result
    const timer = setTimeout(() => {
      setIsChecking(false);
    }, 800);
    
    if (token) {
      dispatch({ 
        type: "[login action] confirmed login",
        payload: token
      });
    }
    
    setIsChecking(false);
    
    return () => clearTimeout(timer);
  }, [dispatch]);

  if (isChecking) {
    return <Loader />;
  }

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/" element={
          isLogin() ? <Navigate to="/dashboard" replace /> : <Navigate to="/page-login" replace />
        } />
        <Route path="/page-login" element={<Login />} />
        <Route path="/page-register" element={<Registration />} />
        <Route path="/page-forgot-password" element={<ForgotPassword />} />
        <Route path="/*" element={<Admin />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </Suspense>
  );
}

export default App;
