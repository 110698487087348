import React, { useState, useEffect } from "react";
import AWS from "aws-sdk";
import defaultImage from "../../images/defaultDp.jpg";
// import { KEYCHAIN } from '../utils/keychain';

// Configure AWS credentials
AWS.config.update({
  region: process.env.REACT_APP_REGION || "us-east-2",
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.REACT_APP_POOL_ID || "us-east-2:f54b4777-c057-4d16-a34c-e5882f882434",
  }),
});

const S3Image = ({ imageKey, style, showModal }) => {
  const [imageData, setImageData] = useState(null);

  const onImageError = (e) => {
    e.target.src = defaultImage;
  };

  useEffect(() => {
    const fetchImageData = async () => {
      if (!imageKey) {
        return;
      }

      // Check if imageKey is an HTTPS URL
      if (imageKey.startsWith("https://")) {
        try {
          const response = await fetch(imageKey);
          const blob = await response.blob();
          const reader = new FileReader();

          reader.onloadend = () => {
            const base64data = reader.result.split(",")[1];
            setImageData(base64data);
          };

          reader.readAsDataURL(blob);
        } catch (error) {
          console.error("Error converting HTTPS image to base64:", error);
        }
      } else {
        // Handle S3 image as before
        try {
          const s3 = new AWS.S3();
          const params = {
            Bucket: process.env.REACT_APP_BUCKET_NAME || "xguardapp",
            Key: imageKey,
          };
          const data = await s3.getObject(params).promise();
          const base64Image = data.Body.toString("base64");

          setImageData(base64Image);
        } catch (error) {
          console.error("Error fetching image:", error);
        }
      }
    };

    fetchImageData();
  }, [imageKey]);

  if (!imageData) {
    return (
      <div>
        <img height={60} width={60} src={defaultImage} className={style} />
      </div>
    );
  }

  return (
    <div>
      <img
        height={showModal ? 400 : 60}
        width={showModal ? 400 : 60}
        className={style}
        src={`data:image/png;base64,${imageData}`}
        alt="S3 Content"
        onError={onImageError}
      />
    </div>
  );
};

export default S3Image;
