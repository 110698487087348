import React from "react";
import { Modal, Button } from "react-bootstrap";
import S3Image from "../components/S3Image";

const ImageModal = ({ show, onHide, imageUrl }) => {
  return (
    <Modal show={show} onHide={onHide} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Image View</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <S3Image imageKey={imageUrl} style="profile-img" showModal={true} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImageModal;
