import React, { useCallback, useEffect, useState } from "react";
import { Dropdown, Card, Table, Badge, Col } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import { getFeeHistoryApi, getFeeListApi } from "..//components/APIs/Api";
import Lottie from "lottie-react";
import animationData from "..//../images/loding.json";
import moment from "moment";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import Pagination from "../components/Common/Pagination";

export default function FeeDetails() {
  const [loader, setLoader] = useState(true);
  const [usersList, setUsersList] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const location = useLocation();
  const feeName = location.state;

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  //============usersList============
  async function tableData() {
    setLoader(true);
    try {
      const res = await getFeeHistoryApi(currentPage, itemsPerPage, feeName);
      setUsersList(res);
    } catch (err) {
      toast.error(
        err?.response?.data?.data
          ? err?.response?.data?.data
          : err?.response?.data?.message
      );
    } finally {
      setLoader(false);
    }
  }
  const totalItems = usersList?.data?.totalRecords;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  useEffect(() => {
    tableData();
    scrollToTop();
  }, [currentPage]);

  return (
    <>
      <div>
        <PageTitle activeMenu="Fee History" motherMenu="Fee Management" />

        <Col>
          {loader ? (
            <div className="">
              <Lottie
                style={{ height: "200px", marginTop: "45px", padding: "30px" }}
                animationData={animationData}
              />
            </div>
          ) : (
            <Card>
              <Card.Body>
                <Table responsive>
                  <thead style={{ color: "black" }}>
                    <tr>
                      <th>
                        <strong>NAME</strong>
                      </th>
                      <th className="text-center">
                        <strong>Previous Value</strong>
                      </th>
                      <th className="text-center">
                        <strong>current Value</strong>
                      </th>
                      <th>
                        <strong>Created at</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersList?.data?.data?.map((item, i) => (
                      <tr key={item?._id}>
                        <td>{item?.name}</td>
                        <td className="text-center">{item?.previousValue}%</td>
                        <td className="text-center">{item?.currentValue}%</td>

                        <td>{moment(item?.updatedAt).format("ll")}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {usersList?.data?.data?.length === 0 && (
                  <div className="justify-content-center d-flex my-5 ">
                    Sorry, Data Not Found!
                  </div>
                )}
                {usersList?.data?.data?.length > 0 && (
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="dataTables_info">
                      Total{" "}
                      <span className="font-weight-bold">{totalItems} </span>
                    </div>
                    <Pagination
                      pageCount={totalPages}
                      pageValue={currentPage}
                      setPage={setCurrentPage}
                    />
                  </div>
                )}
              </Card.Body>
            </Card>
          )}
        </Col>
      </div>
    </>
  );
}
