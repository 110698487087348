import React, { useCallback, useEffect, useState } from "react";
import {
  Dropdown,
  Card,
  Table,
  Badge,
  Col,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import {
  getCategoryApi,
  addCategoryApi,
  editCategoryApi,
  actionCategoryApi,
  deleteCategoryApi,
  updateCategoryOrderApi,
  toggleCategoryVisibilityApi,
} from "..//components/APIs/Api";
import Lottie from "lottie-react";
import defaultImg from "../../images/defaultDp.jpg";
import animationData from "..//../images/loding.json";

import moment from "moment";
import Filter from "../components/Filter/Filter";
import Pagination from "../components/Common/Pagination";
import Switch from "react-switch";
import S3Image from "../components/S3Image";
import CustomTooltip from "../components/Tooltip";
import { useNavigate } from "react-router-dom";
import AddCategoryModal from "../modal/AddCategoryModal";
import toast from "react-hot-toast";
import ConfirmationModal from "../modal/ConfirmationModal";
import ImageModal from "../modal/imageModal";

export default function CategoryManagement() {
  const [loader, setLoader] = useState(true);
  const [search, setSearch] = useState("");
  const [usersList, setUsersList] = useState();
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [addCategoryModal, setAddCategoryModal] = useState(false);
  const [editCategoryModal, setEditCategoryModal] = useState(false);
  const [deleteCategoryModal, setDeleteCategoryModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [isReordering, setIsReordering] = useState(false);
  const [isUpdatingVisibility, setIsUpdatingVisibility] = useState(false);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setShowImageModal(true);
  };

  const handleSearch = useCallback(
    debounce((value) => {
      setSearch(value);
      setCurrentPage(0);
    }, 600),
    []
  );

  //============usersList============
  async function tableData() {
    setLoader(true);
    try {
      console.log("Fetching categories...");
      const res = await getCategoryApi(currentPage, itemsPerPage, search);
      console.log("API Response:", res);
      
      if (res?.data?.data) {
        setUsersList(res);
        console.log("Categories loaded successfully:", res.data.data);
      } else {
        console.error("Invalid response structure:", res);
        throw new Error("Invalid response from server");
      }
    } catch (err) {
      console.error("Error fetching categories:", err);
      console.error("Error details:", {
        message: err.message,
        response: err.response,
        stack: err.stack
      });
      
      toast.error(
        err?.response?.data?.message || 
        err?.message || 
        "Failed to fetch categories"
      );
      setUsersList(null);
    } finally {
      setLoader(false);
    }
  }

  //=========pagination=============
  const totalItems = usersList?.data?.totalRecords;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  useEffect(() => {
    console.log("Effect triggered with:", { currentPage, itemsPerPage, search });
    if (itemsPerPage * currentPage !== totalItems) {
      setCurrentPage(0);
    }
    tableData().catch(err => {
      console.error("Effect error:", err);
      setLoader(false);
    });
    scrollToTop();
  }, [itemsPerPage]);

  useEffect(() => {
    console.log("Search/page effect triggered:", { search, currentPage, itemsPerPage });
    if (search.length > 0) {
      setCurrentPage(0);
    }
    tableData().catch(err => {
      console.error("Effect error:", err);
      setLoader(false);
    });
    scrollToTop();
  }, [currentPage, itemsPerPage, search]);

  const handleReorder = async (categories) => {
    try {
      const orderData = categories.map((category, index) => ({
        _id: category._id,
        order: index
      }));
      await updateCategoryOrderApi(orderData);
      toast.success("Category order updated successfully");
      tableData(); // Refresh the list
    } catch (err) {
      toast.error(err?.response?.data?.message || "Failed to update category order");
    }
  };

  const moveCategory = (fromIndex, toIndex) => {
    const newCategories = [...usersList.data.data];
    const [movedCategory] = newCategories.splice(fromIndex, 1);
    newCategories.splice(toIndex, 0, movedCategory);
    setUsersList(prev => ({
      ...prev,
      data: {
        ...prev.data,
        data: newCategories
      }
    }));
  };

  const handleVisibilityToggle = async (categoryId, currentVisibility) => {
    try {
      setIsUpdatingVisibility(true);
      const newVisibility = !currentVisibility;
      console.log("Toggling visibility:", { categoryId, currentVisibility, newVisibility });
      
      const response = await toggleCategoryVisibilityApi(categoryId, newVisibility);
      console.log("Toggle response:", response.data);
      
      if (response.data && response.data.status === "success") {
        toast.success(response.data.message || "Category visibility updated successfully");
        await tableData(); // Refresh the list after successful update
      } else {
        throw new Error(response.data?.message || "Failed to update category visibility");
      }
    } catch (err) {
      console.error("Toggle visibility error:", err);
      toast.error(err?.response?.data?.message || err.message || "Failed to update category visibility");
      // Revert the switch state by refreshing the data
      await tableData();
    } finally {
      setIsUpdatingVisibility(false);
    }
  };

  return (
    <>
      <div>
        <PageTitle
          activeMenu="Category List"
          motherMenu="Category Management"
        />
        <div className="pb-4 d-flex justify-content-between w-100">
          <div style={{ width: "40%" }}>
            <Filter searchType="search" setSearch={handleSearch} />
          </div>
          <div>
            <button
              className="btn btn-primary btn me-2"
              onClick={() => setIsReordering(!isReordering)}
            >
              {isReordering ? "Save Order" : "Reorder Categories"}
            </button>
            <button
              className="btn btn-primary btn"
              onClick={() => setAddCategoryModal(true)}
            >
              Add Category
            </button>
          </div>
        </div>
        <Col>
          {loader ? (
            <div className="">
              <Lottie
                style={{ height: "200px", marginTop: "45px", padding: "30px" }}
                animationData={animationData}
              />
            </div>
          ) : (
            <Card>
              <Card.Body>
                <Table responsive>
                  <thead style={{ color: "black" }}>
                    <tr>
                      <th>
                        <strong>IMAGE</strong>
                      </th>
                      <th>
                        <strong>NAME</strong>
                      </th>
                      <th>
                        <strong>Created at</strong>
                      </th>
                      <th>
                        <strong>VISIBILITY</strong>
                      </th>
                      {isReordering && (
                        <th>
                          <strong>ORDER</strong>
                        </th>
                      )}
                      <th className="text-center">
                        <strong>ACTION</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersList?.data?.data?.map((item, i) => (
                      <tr key={item._id || i}>
                        <td
                          onClick={() => {
                            handleImageClick(item?.logo);
                          }}
                        >
                          <S3Image
                            imageKey={item?.logo}
                            style={"profile-img"}
                          />
                        </td>
                        <td>
                          {item?.name?.length > 15
                            ? item?.name.slice(0, 15) + "..."
                            : item?.name || ""}
                        </td>
                        <td>{moment(item?.createdAt).format("ll")}</td>
                        <td>
                          <Switch
                            checked={item.isVisible ?? true}
                            onChange={() => handleVisibilityToggle(item._id, item.isVisible ?? true)}
                            disabled={isUpdatingVisibility}
                            onColor="#86d3ff"
                            onHandleColor="#2693e6"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                            className="react-switch"
                            id="material-switch"
                          />
                        </td>
                        {isReordering && (
                          <td>
                            <div className="d-flex align-items-center">
                              <button
                                className="btn btn-sm btn-outline-primary me-2"
                                onClick={() => moveCategory(i, Math.max(0, i - 1))}
                                disabled={i === 0}
                              >
                                ↑
                              </button>
                              <button
                                className="btn btn-sm btn-outline-primary"
                                onClick={() => moveCategory(i, Math.min(usersList.data.data.length - 1, i + 1))}
                                disabled={i === usersList.data.data.length - 1}
                              >
                                ↓
                              </button>
                            </div>
                          </td>
                        )}
                        <td className="text-center">
                          <div className="display-flex gap">
                            <CustomTooltip content={"Delete"}>
                              <div
                                className="btn btn-danger btn-xs sharp hover-item-transition display-flex"
                                style={{
                                  backgroundColor: "red",
                                  color: "white",
                                }}
                                onClick={() => {
                                  setSelectedCategory(item);
                                  setDeleteCategoryModal(true);
                                }}
                              >
                                <i className="fa fa-trash"></i>
                              </div>
                            </CustomTooltip>
                            <CustomTooltip content={"Edit"}>
                              <div
                                className="btn btn-xs sharp hover-item-transition display-flex"
                                style={{
                                  backgroundColor: "#2f4cdd",
                                  color: "white",
                                }}
                                onClick={() => {
                                  setSelectedCategory(item);
                                  setEditCategoryModal(true);
                                }}
                              >
                                <i className="fas fa-pencil-alt"></i>
                              </div>
                            </CustomTooltip>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {isReordering && (
                  <div className="mt-3 text-end">
                    <button
                      className="btn btn-success"
                      onClick={() => handleReorder(usersList.data.data)}
                    >
                      Save New Order
                    </button>
                  </div>
                )}
                {usersList?.data?.data?.length === 0 && (
                  <div className="justify-content-center d-flex my-5 ">
                    Sorry, Data Not Found!
                  </div>
                )}
                {usersList?.length !== 0 && (
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="dataTables_info">
                      Total categories{" "}
                      <span
                        style={{
                          color: "rgb(243, 108, 3)",
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                        {totalItems}
                      </span>
                    </div>
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: "10px" }}
                    >
                      <Pagination
                        pageCount={totalPages}
                        pageValue={currentPage}
                        setPage={setCurrentPage}
                      />
                    </div>
                  </div>
                )}
              </Card.Body>
            </Card>
          )}
        </Col>
      </div>
      {deleteCategoryModal && (
        <ConfirmationModal
          show={deleteCategoryModal}
          onHide={() => setDeleteCategoryModal(false)}
          content={"Are you sure you want to Delete this Category ?"}
          api={deleteCategoryApi}
          data={selectedCategory}
          refresh={tableData}
          successMessage={"Category Deleted Successfully"}
        />
      )}
      {addCategoryModal && (
        <AddCategoryModal
          show={addCategoryModal}
          onHide={() => setAddCategoryModal(false)}
          api={addCategoryApi}
          refresh={tableData}
          title={"Add Category"}
          successMessage={"Add Category Successfully"}
          buttonTitle={"Add"}
        />
      )}
      {editCategoryModal && (
        <AddCategoryModal
          show={editCategoryModal}
          onHide={() => setEditCategoryModal(false)}
          api={editCategoryApi}
          refresh={tableData}
          title={"Update Category"}
          successMessage={"Updated Category Successfully"}
          buttonTitle={"Update"}
          selectedCategory={selectedCategory}
        />
      )}
      <ImageModal
        show={showImageModal}
        onHide={() => setShowImageModal(false)}
        imageUrl={selectedImage}
      />
    </>
  );
}
