import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import {
  loadingToggleAction,
  loginAction,
} from "../../store/actions/AuthActions";
import logofinawtext from "../../images/logofinawtext.png";
import { toast } from "react-hot-toast";
// import logoTextName

function Login(props) {
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(true);
  let errorsObj = { email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Check if user is already logged in
  useEffect(() => {
    const token = localStorage.getItem("xguardToken");
    if (token) {
      // Already logged in, redirect to dashboard
      navigate("/dashboard");
    }
  }, [navigate]);

  // Clear any existing data on mount to ensure clean login
  useEffect(() => {
    localStorage.removeItem("xguardToken");
    localStorage.removeItem("xguardAdminRole");
    setLoader(false);
  }, []);

  function onLogin(e) {
    e.preventDefault();
    if (email === "" || password === "") {
      toast.error("Please enter email and password");
      return;
    }
    dispatch(loadingToggleAction(true));
    
    // Original authentication flow - role saving is now done in AuthActions.js
    dispatch(loginAction(email, password, navigate, setLoader));
  }

  return (
    <div className="login-form-bx">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-md-7 box-skew d-flex">
            <div className="authincation-content">
              <div className="mb-4">
                <h3 className="mb-1 font-w600">Welcome to XGuard App</h3>
                <p className="">Sign in by entering information below</p>
              </div>
              {props.errorMessage && (
                <div className="bg-red-300 text-red-900 border border-red-900 p-1 my-2">
                  {props.errorMessage}
                </div>
              )}
              {props.successMessage && (
                <div className="bg-green-300 text-green-900 border border-green-900 p-1 my-2">
                  {props.successMessage}
                </div>
              )}
              <form onSubmit={onLogin}>
                <div className="form-group">
                  <label className="mb-2 ">
                    <strong className="">Email</strong>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    required
                    placeholder="username"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {errors.email && (
                    <div className="text-danger fs-12">{errors.email}</div>
                  )}
                </div>
                <div className="form-group">
                  <label className="mb-2 ">
                    <strong className="">Password</strong>
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    required
                    placeholder="Enter password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  {errors.password && (
                    <div className="text-danger fs-12">{errors.password}</div>
                  )}
                </div>

                <div className="text-center">
                  <button
                    className={`btn btn-block btn-primary ${
                      loader ? "disabled" : ""
                    }`}
                    disabled={loader}
                  >
                    {loader ? (
                      <div
                        className="d-flex align-items-center justify-content-center "
                        style={{ gap: "5px" }}
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading...
                      </div>
                    ) : (
                      "Sign In"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="col-lg-6 col-md-5 d-flex box-skew1">
            <div className="inner-content align-self-center">
              <div className="login-logo text-center">
                <img src={logofinawtext} alt="XGuard Logo" className="logo-text ml-1" style={{ maxWidth: "300px" }} />
              </div>
              {/* <h2 className="m-b10 text-white">Login To You Now</h2> */}
              {/* <p className="m-b40 text-white text-center">
                Pets provide many benefits to humans. They comfort us and they
                give us companionship.{" "}
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(Login);
