import React, { useCallback, useEffect, useState } from "react";
import { Card, Col, Row, Badge, Button, Nav, Tab, Form, InputGroup } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import { userManagementList, blockUserApi, userKycApi } from "../components/APIs/Api";
import S3Image from "../components/S3Image";
import CustomTooltip from "../components/Tooltip";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Filter from "../components/Filter/Filter";
import { userTypeOptions } from "../components/Filter/FilterLable";
import XGourdLoader from "../components/XGourdLoader";
import ImageModal from "../modal/imageModal";

const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), delay);
  };
};

export default function EnhancedUserManagement() {
  const [loader, setLoader] = useState(true);
  const [search, setSearch] = useState("");
  const [usersList, setUsersList] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(12);
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState({ userType: "", state: "", activityLevel: "" });
  const [selectedImage, setSelectedImage] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [viewMode, setViewMode] = useState("grid");

  const navigate = useNavigate();

  const handleSearch = useCallback(
    debounce((value) => {
      setSearch(value);
      setCurrentPage(0);
    }, 600),
    []
  );

  const fetchUserList = async () => {
    setLoader(true);
    try {
      const res = await userManagementList(
        currentPage,
        itemsPerPage,
        search,
        "",  // For now, no KYC filter used
        filters.userType,
        "active"
      );
      
      // Simulated data for location and other fields
      // In a real implementation, this would be fetched from the backend
      if (res?.data?.data) {
        const states = [
          { state: "New York", cities: ["New York City", "Buffalo", "Albany"] },
          { state: "California", cities: ["Los Angeles", "San Francisco", "San Diego"] },
          { state: "Texas", cities: ["Houston", "Austin", "Dallas"] },
          { state: "Florida", cities: ["Miami", "Orlando", "Tampa"] },
          { state: "Nevada", cities: ["Las Vegas", "Reno", "Henderson"] }
        ];

        const enhancedData = res.data.data.map(user => {
          const randomState = states[Math.floor(Math.random() * states.length)];
          const randomCity = randomState.cities[Math.floor(Math.random() * randomState.cities.length)];
          
          return {
            ...user,
            jobsCompleted: user.userType === "guardian" ? Math.floor(Math.random() * 30) : 0,
            serviceRequests: user.userType === "civilian" ? Math.floor(Math.random() * 15) : 0,
            totalEarnings: user.userType === "guardian" ? Math.floor(Math.random() * 10000) : 0,
            totalSpending: user.userType === "civilian" ? Math.floor(Math.random() * 5000) : 0,
            state: randomState.state,
            city: randomCity,
            activityLevel: ["high", "medium", "low"][Math.floor(Math.random() * 3)]
          };
        });
        
        res.data.data = enhancedData;
        setUsersList(res);
      }
    } catch (err) {
      toast.error(err?.response?.data?.data || err?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  };

  const handleFilterChange = (filterType, value) => {
    setFilters(prev => ({
      ...prev,
      [filterType]: value
    }));
    setCurrentPage(0);
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setShowImageModal(true);
  };

  const onViewUser = (user) => {
    navigate("/user-details", { state: { ...user, previousPage: currentPage } });
  };

  // Apply filters to the user list
  const getFilteredUsers = () => {
    if (!usersList?.data?.data) return [];
    
    return usersList.data.data.filter(user => {
      const matchesUserType = !filters.userType || user.userType === filters.userType;
      const matchesState = !filters.state || user.state === filters.state;
      const matchesActivityLevel = !filters.activityLevel || user.activityLevel === filters.activityLevel;
      
      return matchesUserType && matchesState && matchesActivityLevel;
    });
  };

  // Load users on component mount and when filters change
  useEffect(() => {
    fetchUserList();
  }, [currentPage, search, filters.userType]);

  // Get filtered users
  const filteredUsers = getFilteredUsers();
  const totalItems = filteredUsers.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  
  // Load more users
  const loadMore = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(prev => prev + 1);
    }
  };

  // Add this new component for the filter section
  const FilterSection = () => {
    const states = ["New York", "California", "Texas", "Florida", "Nevada"];
    
    return (
      <div className="mb-4">
        <Row>
          <Col sm={4}>
            <Form.Group>
              <Form.Label>User Type</Form.Label>
              <Form.Select
                value={filters.userType}
                onChange={(e) => handleFilterChange("userType", e.target.value)}
              >
                <option value="">All Types</option>
                <option value="guardian">Guardian</option>
                <option value="civilian">Civilian</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group>
              <Form.Label>State</Form.Label>
              <Form.Select
                value={filters.state}
                onChange={(e) => handleFilterChange("state", e.target.value)}
              >
                <option value="">All States</option>
                {states.map(state => (
                  <option key={state} value={state}>{state}</option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col sm={4}>
            <Form.Group>
              <Form.Label>Activity Level</Form.Label>
              <Form.Select
                value={filters.activityLevel}
                onChange={(e) => handleFilterChange("activityLevel", e.target.value)}
              >
                <option value="">All Levels</option>
                <option value="high">High</option>
                <option value="medium">Medium</option>
                <option value="low">Low</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <>
      <PageTitle activeMenu="User Management" motherMenu="Admin" />
      
      <Row className="mb-4">
        <Col xl={12}>
          <Card>
            <Card.Body>
              <Row>
                <Col lg={4} md={6} sm={12}>
                  <InputGroup className="mb-3">
                    <InputGroup.Text>
                      <i className="fa fa-search"></i>
                    </InputGroup.Text>
                    <Form.Control
                      placeholder="Search by name, email, or phone"
                      onChange={(e) => handleSearch(e.target.value)}
                    />
                  </InputGroup>
                </Col>
                
                <Col lg={2} md={6} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Select
                      value={filters.userType}
                      onChange={(e) => handleFilterChange('userType', e.target.value)}
                    >
                      <option value="">All Roles</option>
                      <option value="guardian">Guardian</option>
                      <option value="civilian">Civilian</option>
                      <option value="agency">Agency</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                
                <Col lg={2} md={6} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Select
                      value={filters.state}
                      onChange={(e) => handleFilterChange('state', e.target.value)}
                    >
                      <option value="">All States</option>
                      <option value="New York">New York</option>
                      <option value="California">California</option>
                      <option value="Texas">Texas</option>
                      <option value="Florida">Florida</option>
                      <option value="Nevada">Nevada</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                
                <Col lg={2} md={6} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Select
                      value={filters.activityLevel}
                      onChange={(e) => handleFilterChange('activityLevel', e.target.value)}
                    >
                      <option value="">All Activity Levels</option>
                      <option value="high">High Activity</option>
                      <option value="medium">Medium Activity</option>
                      <option value="low">Low Activity</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                
                <Col lg={2} md={12} sm={12}>
                  <div className="d-flex gap-2 justify-content-end mb-3">
                    <Button 
                      variant={viewMode === "grid" ? "primary" : "outline-primary"}
                      onClick={() => setViewMode("grid")}
                    >
                      <i className="fas fa-th me-1"></i> Grid
                    </Button>
                    <Button 
                      variant={viewMode === "list" ? "primary" : "outline-primary"}
                      onClick={() => setViewMode("list")}
                    >
                      <i className="fas fa-list me-1"></i> List
                    </Button>
                  </div>
                </Col>
              </Row>
              
              {/* Add Clear Filters button */}
              {(filters.userType || filters.state || filters.activityLevel || search) && (
                <Row>
                  <Col xs={12}>
                    <Button 
                      variant="outline-secondary"
                      size="sm"
                      onClick={() => {
                        setFilters({ userType: "", state: "", activityLevel: "" });
                        setSearch("");
                      }}
                    >
                      <i className="fas fa-times me-1"></i> Clear All Filters
                    </Button>
                  </Col>
                </Row>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      
      {loader ? (
        <div className="text-center mt-5">
          <XGourdLoader />
        </div>
      ) : (
        <Row>
          {viewMode === "grid" ? (
            // Grid view with cards
            <>
              {filteredUsers.map((user, index) => (
                <Col xl={3} lg={4} md={6} sm={12} className="mb-4" key={index}>
                  <Card className="h-100 user-card" onClick={() => onViewUser(user)}>
                    <Card.Header className="text-center bg-light">
                      <div className="user-image position-relative mx-auto" style={{ width: '80px', height: '80px' }}>
                        {user.profileImage ? (
                          <img
                            src={user.profileImage}
                            alt={user.fullName || "User"}
                            className="rounded-circle"
                            style={{ width: '100%', height: '100%', objectFit: 'cover', cursor: 'pointer' }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleImageClick(user.profileImage);
                            }}
                          />
                        ) : (
                          <div className="rounded-circle d-flex align-items-center justify-content-center bg-primary text-white" style={{ width: '100%', height: '100%' }}>
                            {(user.fullName || "User").charAt(0).toUpperCase()}
                          </div>
                        )}
                        
                        <Badge 
                          bg={user.userType === "guardian" ? "info" : user.userType === "agency" ? "primary" : "warning"} 
                          className="position-absolute bottom-0 end-0"
                        >
                          {user.userType === "guardian" ? "Guardian" : user.userType === "agency" ? "Agency" : "Civilian"}
                        </Badge>
                      </div>
                    </Card.Header>
                    
                    <Card.Body>
                      <h5 className="text-center mb-3">{user.fullName || "User"}</h5>
                      
                      <div className="user-details">
                        {user.userType === "guardian" || user.userType === "agency" ? (
                          <>
                            <div className="d-flex justify-content-between mb-2">
                              <span><i className="fa fa-tasks me-1"></i> Jobs Completed:</span>
                              <strong>{user.jobsCompleted}</strong>
                            </div>
                            <div className="d-flex justify-content-between mb-2">
                              <span><i className="fa fa-dollar-sign me-1"></i> Total Earnings:</span>
                              <strong>${user.totalEarnings.toLocaleString()}</strong>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="d-flex justify-content-between mb-2">
                              <span><i className="fa fa-tasks me-1"></i> Service Requests:</span>
                              <strong>{user.serviceRequests}</strong>
                            </div>
                            <div className="d-flex justify-content-between mb-2">
                              <span><i className="fa fa-dollar-sign me-1"></i> Total Spending:</span>
                              <strong>${user.totalSpending.toLocaleString()}</strong>
                            </div>
                          </>
                        )}
                        
                        <div className="d-flex justify-content-between mb-2">
                          <span><i className="fa fa-map-marker-alt me-1"></i> Location:</span>
                          <strong>{user.city}, {user.state}</strong>
                        </div>
                        
                        <div className="d-flex justify-content-between">
                          <span><i className="fa fa-briefcase me-1"></i> Activity:</span>
                          <Badge 
                            bg={user.activityLevel === "high" ? "success" : user.activityLevel === "medium" ? "warning" : "danger"}
                          >
                            {user.activityLevel.charAt(0).toUpperCase() + user.activityLevel.slice(1)}
                          </Badge>
                        </div>
                      </div>
                    </Card.Body>
                    
                    <Card.Footer className="bg-light">
                      <Button 
                        variant="outline-primary" 
                        size="sm" 
                        className="w-100"
                        onClick={(e) => {
                          e.stopPropagation();
                          onViewUser(user);
                        }}
                      >
                        View Profile
                      </Button>
                    </Card.Footer>
                  </Card>
                </Col>
              ))}
              
              {filteredUsers.length === 0 && (
                <Col xs={12}>
                  <Card>
                    <Card.Body className="text-center">
                      <h4>No users found matching the selected filters</h4>
                      <Button 
                        variant="primary" 
                        onClick={() => {
                          setFilters({ userType: "", state: "", activityLevel: "" });
                          setSearch("");
                        }}
                      >
                        Clear Filters
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              )}
            </>
          ) : (
            // List view
            <Col xs={12}>
              <Card>
                <Card.Body>
                  <div className="table-responsive">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>User</th>
                          <th>Role</th>
                          <th>Email</th>
                          <th>City</th>
                          <th>State</th>
                          <th>Activity</th>
                          <th>
                            {filters.userType === "civilian" ? "Total Spending" : "Total Earnings"}
                          </th>
                          <th>
                            {filters.userType === "civilian" ? "Service Requests" : "Jobs Completed"}
                          </th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredUsers.map((user, index) => (
                          <tr key={index}>
                            <td>
                              <div className="d-flex align-items-center">
                                <div style={{ width: '40px', height: '40px', marginRight: '10px' }}>
                                  {user.profileImage ? (
                                    <img
                                      src={user.profileImage}
                                      alt={user.fullName || "User"}
                                      className="rounded-circle"
                                      style={{ width: '100%', height: '100%', objectFit: 'cover', cursor: 'pointer' }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleImageClick(user.profileImage);
                                      }}
                                    />
                                  ) : (
                                    <div className="rounded-circle d-flex align-items-center justify-content-center bg-primary text-white" style={{ width: '100%', height: '100%' }}>
                                      {(user.fullName || "User").charAt(0).toUpperCase()}
                                    </div>
                                  )}
                                </div>
                                <div>
                                  <strong>{user.fullName || "User"}</strong>
                                </div>
                              </div>
                            </td>
                            <td>
                              <Badge 
                                bg={user.userType === "guardian" ? "info" : user.userType === "agency" ? "primary" : "warning"}
                              >
                                {user.userType === "guardian" ? "Guardian" : user.userType === "agency" ? "Agency" : "Civilian"}
                              </Badge>
                            </td>
                            <td>{user.email}</td>
                            <td>{user.city}</td>
                            <td>{user.state}</td>
                            <td>
                              <Badge 
                                bg={user.activityLevel === "high" ? "success" : user.activityLevel === "medium" ? "warning" : "danger"}
                              >
                                {user.activityLevel.charAt(0).toUpperCase() + user.activityLevel.slice(1)}
                              </Badge>
                            </td>
                            <td>
                              ${(user.userType === "civilian" ? user.totalSpending : user.totalEarnings).toLocaleString()}
                            </td>
                            <td>
                              {user.userType === "civilian" ? user.serviceRequests : user.jobsCompleted}
                            </td>
                            <td>
                              <Button 
                                variant="primary" 
                                size="sm"
                                onClick={() => onViewUser(user)}
                              >
                                View
                              </Button>
                            </td>
                          </tr>
                        ))}
                        
                        {filteredUsers.length === 0 && (
                          <tr>
                            <td colSpan="9" className="text-center">
                              <h5 className="my-3">No users found matching the selected filters</h5>
                              <Button 
                                variant="primary" 
                                onClick={() => {
                                  setFilters({ userType: "", state: "", activityLevel: "" });
                                  setSearch("");
                                }}
                              >
                                Clear Filters
                              </Button>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          )}
          
          {/* Load More Button */}
          {filteredUsers.length > 0 && currentPage < totalPages - 1 && (
            <Col xs={12} className="text-center my-4">
              <Button variant="primary" onClick={loadMore}>
                Load More
              </Button>
            </Col>
          )}
        </Row>
      )}
      
      {selectedImage && (
        <ImageModal
          show={showImageModal}
          onHide={() => setShowImageModal(false)}
          imageUrl={selectedImage}
        />
      )}
    </>
  );
} 