import React, { useCallback, useEffect, useState } from "react";
import { Card, Col, Table, Badge } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import {
  userManagementList,
  blockUserApi,
  userKycApi,
} from "../components/APIs/Api";

import Pagination from "../components/Common/Pagination";
import Switch from "react-switch";
import S3Image from "../components/S3Image";
import rejectIcon from "../../images/decline (1).png";
import approveIcon from "../../images/approve.png";
import CustomTooltip from "../components/Tooltip";
import ConfirmationModal from "../modal/ConfirmationModal";
import { useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import Filter from "../components/Filter/Filter";
import {
  configFilterLabels,
  kycOptions,
  userTypeOptions,
} from "../components/Filter/FilterLable";
import { scrollToTop } from "../components/Utils/ScrollOnTop";
import XGourdLoader from "../components/XGourdLoader";
import RejectMessageModal from "../modal/RejectMessageModal";
import ImageModal from "../modal/imageModal";

const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), delay);
  };
};

export default function UserManagement() {
  const [loader, setLoader] = useState(true);
  const [search, setSearch] = useState("");
  const [usersList, setUsersList] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedUsers, setSelectedUsers] = useState(null);
  const [rejectConfirmationModal, setRejectConfirmationModal] = useState(false);
  const [filters, setFilters] = useState({ userType: "", kycType: "" });
  const [selectedActionItems, setSelectedActionItems] = useState([]);
  // const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [filterTypes, setFilterTypes] = useState("active");
  const [selectedImage, setSelectedImage] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const handleSearch = useCallback(
    debounce((value) => {
      setSearch(value);
      setCurrentPage(0);
    }, 600),
    []
  );

  const fetchUserList = async () => {
    setLoader(true);
    try {
      const res = await userManagementList(
        currentPage,
        itemsPerPage,
        search,
        filters.kycType,
        filters.userType,
        filterTypes
      );
      setUsersList(res);
    } catch (err) {
      toast.error(err?.response?.data?.data || err?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  };
  const handleCheckboxChange = (id) => {
    setSelectedActionItems((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((itemId) => itemId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectAll = () => {
    const allItemIds = usersList?.data?.data?.map((item) => item._id) || [];

    if (selectedActionItems.length === allItemIds.length) {
      setSelectedActionItems([]);
    } else {
      setSelectedActionItems(allItemIds);
    }
  };

  const blockUser = async (id) => {
    setLoader(true);
    try {
      const res = await blockUserApi(
        id,
        filterTypes === "active" ? "inactive" : "active"
      );
      fetchUserList();
      setSelectedActionItems([]);
      toast.success(res?.data?.message);
    } catch (err) {
      toast.error(err?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  };
  async function handleApproveUser(id, status) {
    setLoader(true);
    try {
      const postData = {
        _id: id,
        status: status,
      };
      const response = await userKycApi(postData);
      toast.success("User Approved Successfully");
      fetchUserList();
    } catch (error) {
      toast.error(error);
    } finally {
      setLoader(false);
    }
  }
  function onSendItem(item) {
    // Save all current list parameters for exact restoration
    const listParams = {
      search: search,
      filterTypes: filterTypes,
      filters: filters
    };
    
    // Save to session storage
    sessionStorage.setItem('userListPage', currentPage.toString());
    sessionStorage.setItem('userListParams', JSON.stringify(listParams));
    
    // Pass all parameters in navigation state
    navigate("/user-details", { 
      state: {
        ...item,
        previousPage: currentPage
      } 
    });
  }

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setShowImageModal(true);
  };

  const totalItems = usersList?.data?.totalRecords;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // Handle back navigation from user details
  useEffect(() => {
    // Only apply state if specifically coming back from user details
    if (location.state?.fromUserDetails && location.state?.currentPage !== undefined) {
      console.log('Restoring user list state from navigation');
      
      // Restore page number
      setCurrentPage(location.state.currentPage);
      
      // Restore filters if they exist in the state
      if (location.state.filters) {
        setFilters(location.state.filters);
      }
      
      // Restore filter type
      if (location.state.filterTypes) {
        setFilterTypes(location.state.filterTypes);
      }
      
      // Restore search
      if (location.state.search !== undefined) {
        setSearch(location.state.search);
      }
      
      // Clear the navigation state to prevent re-applying on subsequent renders
      window.history.replaceState({}, document.title);
      
      // Manually trigger data fetch with all the restored parameters
      const fetchData = async () => {
        try {
          setLoader(true);
          const res = await userManagementList(
            location.state.currentPage,
            itemsPerPage,
            location.state.search || search,
            location.state.filters?.kycType || filters.kycType,
            location.state.filters?.userType || filters.userType,
            location.state.filterTypes || filterTypes
          );
          setUsersList(res);
        } catch (err) {
          toast.error(err?.response?.data?.data || err?.response?.data?.message);
        } finally {
          setLoader(false);
        }
      };
      
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  // Also check session storage on component mount
  useEffect(() => {
    const savedPage = sessionStorage.getItem('userListPage');
    if (savedPage && !location.state?.currentPage) {
      console.log('Restoring page from session storage:', savedPage);
      setCurrentPage(parseInt(savedPage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Clear the fromUserDetails flag after initial render so pagination works correctly
  useEffect(() => {
    if (location.state?.fromUserDetails) {
      // After the initial render from user details, clear the flag
      console.log('Clearing navigation state to enable normal pagination');
      window.history.replaceState({}, document.title);
    }
  }, [location.state]);

  // Main data fetch effect - simplified to avoid conflicts
  useEffect(() => {
    // Always fetch when these dependencies change
    console.log('Data fetch triggered by page, search, or filter change');
    fetchUserList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, search, filterTypes, filters.userType, filters.kycType]);

  // Handle page reset when filter changes or if we've gone past available pages
  useEffect(() => {
    if (totalItems > 0 && itemsPerPage * currentPage >= totalItems && currentPage > 0) {
      setCurrentPage(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalItems, itemsPerPage]);

  return (
    <>
      <PageTitle activeMenu="Users List" motherMenu="User Management" />
      <div className="pb-4 d-flex justify-content-between w-100">
        <div style={{ width: "40%" }}>
          <Filter searchType="search" setSearch={handleSearch} />
        </div>
        <div className="display-flex gap">
          <Filter
            filterType={filters.userType}
            setFilterType={(value) =>
              setFilters((prev) => ({ ...prev, userType: value }))
            }
            FilterOption={userTypeOptions}
            filter="filter"
            filterTitle="Select User . . ."
          />
          <Filter
            filterType={filters.kycType}
            setFilterType={(value) =>
              setFilters((prev) => ({ ...prev, kycType: value }))
            }
            FilterOption={kycOptions}
            filter="filter"
            filterTitle="KYC Status"
          />
        </div>
      </div>
      {/* <div className="d-flex pb-4 justify-content-between align-items-center">
        <Filter
          tabs={true}
          FilterOption={configFilterLabels}
          filterType={filterTypes}
          setFilterType={setFilterTypes}
        />
        {selectedActionItems?.length > 0 && (
          <div>
            <button
              className="btn btn-primary"
              onClick={() => blockUser(selectedActionItems)}
            >
              {filterTypes === "active" ? "Inactive" : "Active"}
            </button>
          </div>
        )}
      </div> */}
      <div className="d-flex pb-4 justify-content-between align-items-center">
        <Filter
          tabs={true}
          FilterOption={configFilterLabels.filter(
            (tab) => tab.value !== "deleted"
          )}
          filterType={filterTypes}
          setFilterType={setFilterTypes}
        />
        {selectedActionItems?.length > 0 && (
          <div>
            <button
              className="btn btn-primary"
              onClick={() => blockUser(selectedActionItems)}
            >
              {filterTypes === "active" ? "Inactive" : "Active"}
            </button>
          </div>
        )}
      </div>
      <Col>
        {loader ? (
          <div className="text-center mt-5">
            <XGourdLoader />
          </div>
        ) : (
          <Card>
            <Card.Body>
              <Table responsive>
                <thead>
                  <tr>
                    <th className="width50">
                      <div className="custom-control custom-checkbox checkbox-success check-lg mr-3">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="checkbox1_exam_all"
                          onChange={(e) => {
                            e.stopPropagation();
                            handleSelectAll();
                          }}
                          disabled={filterTypes === "deleted"}
                          checked={
                            usersList?.data?.data?.length > 0 &&
                            selectedActionItems.length ===
                              usersList?.data?.data?.length
                          }
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="checkbox1_exam_all"
                        ></label>
                      </div>
                    </th>
                    <th>IMAGE</th>
                    <th>NAME</th>
                    <th>EMAIL</th>
                    <th>PHONE</th>
                    <th>user Type</th>
                    <th>KYC</th>
                    {/* <th>STATUS</th> */}
                    <th className="text-center">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {usersList?.data?.data?.map((item) => (
                    <tr
                      key={item?._id}
                      className={
                        item.isDeleted
                          ? "disabled-row table-scale"
                          : " table-scale"
                      }
                      // onClick={() => onSendItem(item)}
                      onClick={(e) => {
                        if (!e.target.closest("input[type='checkbox']")) {
                          onSendItem(item);
                        }
                      }}
                    >
                      <td>
                        <div className="custom-control custom-checkbox checkbox-success check-lg mr-3">
                          {/* <input
                            type="checkbox"
                            className="custom-control-input"
                            id={`customCheckBox${item._id}`}
                            onChange={() => handleCheckboxChange(item._id)}
                            checked={selectedActionItems.includes(item._id)}
                          /> */}
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={`customCheckBox${item._id}`}
                            onChange={(e) => {
                              e.stopPropagation(); // Prevent row click event from triggering
                              handleCheckboxChange(item._id);
                            }}
                            checked={selectedActionItems.includes(item._id)}
                          />

                          <label
                            className="custom-control-label"
                            htmlFor={`customCheckBox${item?._id}`}
                          ></label>
                        </div>
                      </td>
                      <td>
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleImageClick(item?.image);
                          }}
                        >
                          <S3Image imageKey={item?.image} style="profile-img" />
                        </div>
                      </td>
                      <td>
                        {item?.name?.length > 15
                          ? `${item?.name.slice(0, 15)}...`
                          : item?.name || ""}
                      </td>
                      <td>
                        {item?.email?.length > 20
                          ? `${item?.email.slice(0, 20)}...`
                          : item?.email}
                      </td>
                      <td>{item?.number}</td>
                      <td
                        className="text-center "
                        style={{ textTransform: "capitalize" }}
                      >
                        {item?.userType}
                      </td>
                      <td>
                        <Badge
                          bg={
                            item?.kycStatus === "pending"
                              ? "info"
                              : item?.kycStatus === "rejected"
                              ? "danger"
                              : "success"
                          }
                        >
                          {item?.kycStatus === "pending"
                            ? "Pending"
                            : item?.kycStatus === "rejected"
                            ? "Rejected"
                            : "Accepted"}
                        </Badge>
                      </td>
                      <td>
                        <div className="display-flex gap">
                          <CustomTooltip content={"Edit"}>
                            <div
                              className="btn btn-xs sharp hover-item-transition display-flex"
                              style={{
                                backgroundColor: "#2f4cdd",
                                color: "white",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                navigate(`/edit-user/${item._id}`);
                              }}
                            >
                              <i className="fas fa-pencil-alt"></i>
                            </div>
                          </CustomTooltip>

                          {/* <CustomTooltip content="Active/Inactive">
                            <div
                              className="btn btn-danger btn-xs sharp hover-item-transition display-flex"
                              onClick={(e) => {
                                e.stopPropagation();
                                setSelectedUsers(item);
                                setIsDeleteModal(true);
                              }}
                            >
                              <i
                                className={`fa ${
                                  item?.isBlocked ? "fa-check" : "fa-ban"
                                }`}
                              ></i>
                            </div>
                          </CustomTooltip> */}

                          {!item?.isBlocked &&
                            (item?.userType === "agency" ||
                              item?.userType === "guardian") && (
                              <>
                                {item?.kycStatus === "pending" && (
                                  <>
                                    <CustomTooltip content="Reject">
                                      <div
                                        className="btn btn-danger btn-xs sharp hover-item-transition display-flex"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setSelectedUsers(item);
                                          setRejectConfirmationModal(true);
                                        }}
                                      >
                                        <img
                                          src={rejectIcon}
                                          height={35}
                                          alt="Reject"
                                        />
                                      </div>
                                    </CustomTooltip>
                                    <CustomTooltip content="Approve">
                                      <div
                                        className="btn btn-primary btn-xs sharp hover-item-transition display-flex"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          handleApproveUser(
                                            item?._id,
                                            "accepted"
                                          );
                                        }}
                                      >
                                        <img
                                          src={approveIcon}
                                          height={30}
                                          alt="Approve"
                                        />
                                      </div>
                                    </CustomTooltip>
                                  </>
                                )}
                              </>
                            )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {usersList?.data?.totalRecords === 0 && (
                <div className="text-center my-5">Sorry, Data Not Found!</div>
              )}
              {usersList?.data?.totalRecords > 0 && (
                <div className="d-flex justify-content-between align-items-center">
                  <div className="dataTables_info">
                    Total Users{" "}
                    <span className="font-weight-bold">{totalItems} </span>
                  </div>
                  <Pagination
                    pageCount={totalPages}
                    pageValue={currentPage}
                    setPage={setCurrentPage}
                  />
                </div>
              )}
            </Card.Body>
          </Card>
        )}
      </Col>
      {/* {isDeleteModal && (
        <ConfirmationModal
          show={isDeleteModal}
          onHide={() => setIsDeleteModal(false)}
          content={"Are you sure you want to Delete this User ?"}
          api={deleteUserApi}
          data={selectedUsers}
          refresh={fetchUserList}
          successMessage={"User Deleted Successfully"}
        />
      )} */}
      {/* {isDeleteModal && (
        <ConfirmationModal
          show={isDeleteModal}
          onHide={() => setIsDeleteModal(false)}
          content={`Are you sure you want to ${
            selectedUsers?.isBlocked ? "Activate" : "Deactivate"
          } this User?`}
          api={blockUserApi}
          data={selectedUsers}
          refresh={fetchUserList}
          successMessage={`User ${
            selectedUsers?.isBlocked ? "Activated" : "Deactivated"
          } Successfully`}
        />
      )} */}
      {rejectConfirmationModal && (
        <RejectMessageModal
          show={rejectConfirmationModal}
          onHide={() => setRejectConfirmationModal(false)}
          data={selectedUsers}
          refresh={fetchUserList}
        />
      )}

      {selectedImage && (
        <ImageModal
          show={showImageModal}
          onHide={() => setShowImageModal(false)}
          imageUrl={selectedImage}
        />
      )}
    </>
  );
}
