import React, { useCallback, useEffect, useState } from "react";
import { Dropdown, Card, Table, Badge, Col } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import { getFeeCountApi } from "..//components/APIs/Api";
import Lottie from "lottie-react";
import animationData from "..//../images/loding.json";
import moment from "moment";
import toast from "react-hot-toast";
import Pagination from "../components/Common/Pagination";
import S3Image from "../components/S3Image";

export default function FeeCountUsers() {
  const [loader, setLoader] = useState(true);
  const [usersList, setUsersList] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  //============usersList============
  async function tableData() {
    setLoader(true);
    try {
      const res = await getFeeCountApi(currentPage, itemsPerPage);
      setUsersList(res);
    } catch (err) {
      toast.error(
        err?.response?.data?.data
          ? err?.response?.data?.data
          : err?.response?.data?.message
      );
    } finally {
      setLoader(false);
    }
  }
  const totalItems = usersList?.data?.totalRecords;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  useEffect(() => {
    tableData();
    scrollToTop();
  }, [currentPage]);

  return (
    <>
      <div>
        <PageTitle activeMenu="Listing" motherMenu="Fee Join Users" />

        <Col>
          {loader ? (
            <div className="">
              <Lottie
                style={{ height: "200px", marginTop: "45px", padding: "30px" }}
                animationData={animationData}
              />
            </div>
          ) : (
            <Card>
              <Card.Body>
                <Table responsive>
                  <thead style={{ color: "black" }}>
                    <tr>
                      <th>IMAGE</th>
                      <th>NAME</th>
                      <th>EMAIL</th>
                      <th>PHONE</th>
                      <th>user Type</th>
                      <th>KYC</th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersList?.data?.data?.map((item, i) => (
                      <tr key={item?._id}>
                        <td>
                          <S3Image imageKey={item?.image} style="profile-img" />
                        </td>
                        <td>{item?.name}</td>
                        <td c>{item?.email}</td>
                        <td>
                          {item?.countryCode} {item?.number}
                        </td>
                        <td>{item?.userType}</td>
                        <td>
                          <Badge
                            bg={
                              item?.kycStatus === "pending"
                                ? "info"
                                : item?.kycStatus === "rejected"
                                ? "danger"
                                : "success"
                            }
                          >
                            {item?.kycStatus === "pending"
                              ? "Pending"
                              : item?.kycStatus === "rejected"
                              ? "Rejected"
                              : "Accepted"}
                          </Badge>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {usersList?.data?.data?.length === 0 && (
                  <div className="justify-content-center d-flex my-5 ">
                    Sorry, Data Not Found!
                  </div>
                )}
                {usersList?.data?.data?.length > 0 && (
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="dataTables_info">
                      Total{" "}
                      <span className="font-weight-bold">{totalItems} </span>
                    </div>
                    <Pagination
                      pageCount={totalPages}
                      pageValue={currentPage}
                      setPage={setCurrentPage}
                    />
                  </div>
                )}
              </Card.Body>
            </Card>
          )}
        </Col>
      </div>
    </>
  );
}
