import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";

const AddRoleModal = ({ show, onHide, onSubmit, editMode, initialData }) => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    isActive: true,
    permissions: [
      {
        resource: "MANAGE_ADMINS",
        actions: []
      },
      {
        resource: "MANAGE_USERS",
        actions: []
      },
      {
        resource: "MANAGE_ROLES",
        actions: []
      },
      {
        resource: "MANAGE_CATEGORIES",
        actions: []
      }
    ]
  });

  const resources = [
    { id: "MANAGE_ADMINS", name: "Admin Management" },
    { id: "MANAGE_USERS", name: "User Management" },
    { id: "MANAGE_ROLES", name: "Role Management" },
    { id: "MANAGE_CATEGORIES", name: "Category Management" }
  ];
  
  const actions = [
    { id: "create", name: "Create" },
    { id: "read", name: "Read" },
    { id: "update", name: "Update" },
    { id: "delete", name: "Delete" }
  ];

  useEffect(() => {
    if (editMode && initialData) {
      // Transform initialData if needed
      let transformedData = {...initialData};
      
      // Ensure permissions array has all required resources
      const resourceIds = resources.map(r => r.id);
      const existingResources = transformedData.permissions?.map(p => p.resource) || [];
      
      resourceIds.forEach(resourceId => {
        if (!existingResources.includes(resourceId)) {
          transformedData.permissions = [
            ...(transformedData.permissions || []),
            { resource: resourceId, actions: [] }
          ];
        }
      });
      
      setFormData(transformedData);
    } else {
      // Reset form for new role
      setFormData({
        name: "",
        description: "",
        isActive: true,
        permissions: resources.map(resource => ({
          resource: resource.id,
          actions: []
        }))
      });
    }
  }, [editMode, initialData, show]);

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value
    });
  };

  const handlePermissionChange = (resourceId, actionId, checked) => {
    setFormData(prevData => {
      const newPermissions = prevData.permissions.map(permission => {
        if (permission.resource === resourceId) {
          const actions = [...permission.actions];
          if (checked) {
            // Add action if not exists
            if (!actions.includes(actionId)) {
              actions.push(actionId);
            }
          } else {
            // Remove action
            const index = actions.indexOf(actionId);
            if (index > -1) {
              actions.splice(index, 1);
            }
          }
          return { ...permission, actions };
        }
        return permission;
      });
      return { ...prevData, permissions: newPermissions };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>{editMode ? 'Edit Role' : 'Add New Role'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Role Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Enter role name"
              required
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              placeholder="Enter role description"
              rows={3}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              name="isActive"
              label="Active"
              checked={formData.isActive}
              onChange={handleInputChange}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Permissions</Form.Label>
            <div className="table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Resource</th>
                    {actions.map(action => (
                      <th key={action.id}>{action.name}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {resources.map(resource => (
                    <tr key={resource.id}>
                      <td>{resource.name}</td>
                      {actions.map(action => {
                        const permission = formData.permissions.find(
                          p => p.resource === resource.id
                        );
                        const isChecked = permission && permission.actions.includes(action.id);
                        
                        return (
                          <td key={action.id} className="text-center">
                            <Form.Check
                              type="checkbox"
                              checked={isChecked}
                              onChange={(e) => 
                                handlePermissionChange(resource.id, action.id, e.target.checked)
                              }
                            />
                          </td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Form.Group>

          <div className="text-end">
            <Button variant="secondary" onClick={onHide} className="me-2">
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              {editMode ? 'Update' : 'Save'}
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddRoleModal; 