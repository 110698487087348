import React, { useCallback, useEffect, useState } from "react";
import { Dropdown, Card, Table, Badge, Col } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Lottie from "lottie-react";
import animationData from "..//../images/loding.json";
import moment from "moment";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import Pagination from "../components/Common/Pagination";
import Rating from "../components/ui/Rating";
import { deleteReviewApi, getRatingApi } from "../components/APIs/Api";
import S3Image from "../components/S3Image";
import halfStar from "../../images/halfStar.png";
import fullStar from "../../images/fullStar.png";
import emptyStar from "../../images/emptyStar.png";
import ConfirmationModal from "../modal/ConfirmationModal";
import ImageModal from "../modal/imageModal";

export default function ReviewManagement() {
  const [loader, setLoader] = useState(true);
  const [usersList, setUsersList] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedActionItems, setSelectedActionItems] = useState(null);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const location = useLocation();
  const feeName = location.state;
  const starImages = {
    fullStar: fullStar,
    halfStar: halfStar,
    emptyStar: emptyStar,
  };
  const [selectedImage, setSelectedImage] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  //============usersList============
  async function tableData() {
    setLoader(true);
    try {
      const res = await getRatingApi(currentPage, itemsPerPage, feeName);
      setUsersList(res);
    } catch (err) {
      toast.error(
        err?.response?.data?.data
          ? err?.response?.data?.data
          : err?.response?.data?.message
      );
    } finally {
      setLoader(false);
    }
  }
  const totalItems = usersList?.data?.totalRecords;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  useEffect(() => {
    tableData();
    scrollToTop();
  }, [currentPage]);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setShowImageModal(true);
  };

  return (
    <>
      <div>
        <PageTitle activeMenu="Listing" motherMenu="Review Management" />

        <Col>
          {loader ? (
            <div className="">
              <Lottie
                style={{ height: "200px", marginTop: "45px", padding: "30px" }}
                animationData={animationData}
              />
            </div>
          ) : (
            <Card>
              <Card.Body>
                <Table responsive>
                  <thead style={{ color: "black" }}>
                    <tr>
                      <th>
                        <strong>image</strong>
                      </th>
                      <th>
                        <strong>NAME</strong>
                      </th>
                      <th>
                        <strong>Service user</strong>
                      </th>
                      <th className="">
                        <strong>Ratings</strong>
                      </th>
                      <th>
                        <strong>Review</strong>
                      </th>
                      <th className="text-center">
                        <strong>Action</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersList?.data?.data?.map((item, i) => (
                      <tr key={item?._id}>
                        <td
                          onClick={() => {
                            handleImageClick(item?.userId?.image);
                          }}
                        >
                          <S3Image
                            imageKey={item?.userId?.image}
                            style={"profile-img"}
                          />
                        </td>

                        <td>
                          {item?.userId?.name}
                          <br />
                          {item?.userId?.email}

                          <br />
                          {item?.userId?.number}
                        </td>
                        <td>
                          <Badge>{item?.serviceUserId?.userType}</Badge>
                          <br />
                          {item?.serviceUserId?.name}
                          <br />
                          {item?.serviceUserId?.number}
                        </td>

                        <td>
                          <Rating
                            rating={item?.avgRating}
                            starImages={starImages}
                          />
                        </td>

                        <td>{item?.comment}</td>
                        <td className="text-center">
                          <div
                            className="btn btn-danger btn-xs sharp hover-item-transition"
                            onClick={() => {
                              setSelectedActionItems(item);
                              setIsDeleteModal(true);
                            }}
                          >
                            <i className="fa fa-trash"></i>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {usersList?.data?.data?.length === 0 && (
                  <div className="justify-content-center d-flex my-5 ">
                    Sorry, Data Not Found!
                  </div>
                )}
                {usersList?.data?.data?.length > 0 && (
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="dataTables_info">
                      Total{" "}
                      <span className="font-weight-bold">{totalItems} </span>
                    </div>
                    <Pagination
                      pageCount={totalPages}
                      pageValue={currentPage}
                      setPage={setCurrentPage}
                    />
                  </div>
                )}
              </Card.Body>
            </Card>
          )}
        </Col>
      </div>
      {isDeleteModal && (
        <ConfirmationModal
          show={isDeleteModal}
          onHide={() => setIsDeleteModal(false)}
          content={"Are you sure you want to Delete this Review ?"}
          api={deleteReviewApi}
          data={selectedActionItems}
          refresh={tableData}
          successMessage={"Review Deleted Successfully"}
        />
      )}
      <ImageModal
        show={showImageModal}
        onHide={() => setShowImageModal(false)}
        imageUrl={selectedImage}
      />
    </>
  );
}
