import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import AWS from "aws-sdk";
import toast from "react-hot-toast";
import axios from "axios";

export default function AddCategoryModal({
  title,
  show,
  onHide,
  buttonTitle,
  api,
  refresh,
  successMessage,
  selectedCategory,
}) {
  const s3 = new AWS.S3();
  const [name, setName] = useState(selectedCategory?.name || "");
  const [nameError, setNameError] = useState("");
  const [loader, setLoader] = useState(false);
  const [image, setImage] = useState(null);
  const [order, setOrder] = useState(selectedCategory?.order || 0);

  const handleUpload = async (file) => {
    if (!file) {
      console.error("No file selected");
      return;
    }

    const params = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: file.name,
      Body: file,
    };

    try {
      const data = await s3.upload(params).promise();
      return data;
    } catch (error) {
      console.error("Upload failed:", error);
      throw error;
    }
  };

  const handleSubmit = async () => {
    try {
      let categoryImage = null;
      if (image) {
        categoryImage = await handleUpload(image);
      }

      const postData = {
        name,
        logo: categoryImage ? categoryImage.Key : selectedCategory?.logo,
        order: parseInt(order),
      };

      if (["Update Category", "Update Skill"].includes(title)) {
        postData._id = selectedCategory?._id;
      }

      const res = await api(postData);
      if (res?.data?.status) {
        toast.success(successMessage);
        onHide();
      }
    } catch (err) {
      toast.error(
        err?.response?.data?.data
          ? err?.response?.data?.data
          : err?.response?.data?.message
      );
    }
  };

  return (
    <Modal className="sortBy" show={show} onHide={onHide} centered>
      <Modal.Header>
        <h3 className="text-center m-0">{title}</h3>
      </Modal.Header>

      <form onSubmit={handleSubmit}>
        <div className="p-3">
          <label className="text-black font-w500">Image</label>
          <div className="contact-name form-group">
            <input
              type="file"
              className="form-control"
              accept=".jpg,.png,.jpeg"
              required={!selectedCategory?.logo && buttonTitle !== "Update"}
              onChange={(e) => setImage(e.target.files[0])}
            />
            {selectedCategory?.logo && <span>{selectedCategory?.logo}</span>}
          </div>
          <label className="text-black font-w500">Name</label>
          <div className="contact-name form-group">
            <input
              type="text"
              className="form-control"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                if (nameError) setNameError("");
              }}
              placeholder="Enter name"
              required
            />
            <span className="text-danger">{nameError}</span>
          </div>
          <label className="text-black font-w500">Order</label>
          <div className="contact-name form-group">
            <input
              type="number"
              className="form-control"
              value={order}
              onChange={(e) => setOrder(e.target.value)}
              placeholder="Enter order"
            />
          </div>
        </div>

        <div className="p-3 d-flex justify-content-end">
          <button
            className="btn btn-sm btn-primary"
            type="submit"
            disabled={loader}
          >
            {loader ? <Spinner animation="border" size="sm" /> : buttonTitle}
          </button>
        </div>
      </form>
    </Modal>
  );
}
