import React, { Component, useContext, useEffect, useState } from "react";
/// Link
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
/// Scroll
import { ThemeContext } from "../../../context/ThemeContext";
import { MenuList } from "./Menu";
import Collapse from 'react-bootstrap/Collapse';

const SideBar = () => {
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);

  const [active, setActive] = useState('Dashboard');
  const [activeSubMenu, setActiveSubMenu] = useState("");
  
  // Initialize state from the URL
  useEffect(() => {
    const path = window.location.pathname;
    
    // Find menu item that matches current path
    const findMenuItem = () => {
      for (const item of MenuList) {
        if (item.to && path.includes(item.to.substring(1))) {
          return item.title;
        }
        
        // Check submenu items
        if (item.content) {
          for (const subItem of item.content) {
            if (subItem.to && path.includes(subItem.to.substring(1))) {
              setActiveSubMenu(subItem.title);
              return item.title;
            }
            
            // Check third level items
            if (subItem.content) {
              for (const thirdItem of subItem.content) {
                if (thirdItem.to && path.includes(thirdItem.to.substring(1))) {
                  setActiveSubMenu(subItem.title);
                  return item.title;
                }
              }
            }
          }
        }
      }
      
      // Default to Dashboard if no match found
      return 'Dashboard';
    };
    
    setActive(findMenuItem());
  }, []);

  // Handles clicks on menu items
  const handleMenuActive = (title) => {
    setActive(title);
    if (title !== active) {
      setActiveSubMenu("");
    }
  };

  // Handles clicks on submenu items
  const handleSubmenuActive = (title) => {
    setActiveSubMenu(title === activeSubMenu ? "" : title);
  };

  // State
  const [hideOnScroll, setHideOnScroll] = useState(true);
  
  // Detect scroll position to hide/show menu
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );

  // Set classes for sidebar hover
  const [ChangeIconSidebar, setChangeIconSidebar] = useState(false);

  return (
    <>
      <div
        onMouseEnter={() => setChangeIconSidebar(true)}
        onMouseLeave={() => setChangeIconSidebar(false)}
        className={`deznav ${iconHover} ${
          sidebarposition.value === "fixed" &&
          sidebarLayout.value === "horizontal" &&
          headerposition.value === "static"
            ? hideOnScroll > 120
              ? "fixed"
              : ""
            : ""
        }`}
      >
        <PerfectScrollbar className="deznav-scroll">
          <ul className="metismenu" id="menu">
            {MenuList.map((item, index) => {
              // Menu title
              if (item.classsChange === "menu-title") {
                return (
                  <li className={item.classsChange} key={index}>
                    {item.title}
                  </li>
                );
              }
              
              // Standard menu items
              return (
                <li
                  className={`${active === item.title ? "mm-active" : ""}`}
                  key={index}
                >
                  {item.content && item.content.length > 0 ? (
                    // Menu with submenu
                    <>
                      <Link
                        to={"#"}
                        className="has-arrow"
                        onClick={() => {
                          handleMenuActive(item.title);
                        }}
                      >
                        {item.iconStyle}
                        <span className="nav-text">{item.title}</span>
                      </Link>
                      <Collapse in={active === item.title}>
                        <ul className={item.classsChange === "mm-collapse" ? "mm-show" : ""}>
                          {item.content.map((subItem, subIndex) => {
                            return (
                              <li key={subIndex} className={activeSubMenu === subItem.title ? "mm-active" : ""}>
                                {subItem.content && subItem.content.length > 0 ? (
                                  // Third level menu
                                  <>
                                    <Link
                                      to={subItem.to || "#"}
                                      className={subItem.hasMenu ? "has-arrow" : ""}
                                      onClick={() => {
                                        handleSubmenuActive(subItem.title);
                                      }}
                                    >
                                      {subItem.title}
                                    </Link>
                                    <Collapse in={activeSubMenu === subItem.title}>
                                      <ul className={item.classsChange === "mm-collapse" ? "mm-show" : ""}>
                                        {subItem.content.map((thirdItem, thirdIndex) => (
                                          <li key={thirdIndex}>
                                            <Link to={thirdItem.to || "#"}>
                                              {thirdItem.title}
                                            </Link>
                                          </li>
                                        ))}
                                      </ul>
                                    </Collapse>
                                  </>
                                ) : (
                                  // Simple submenu item
                                  <Link to={subItem.to || "#"}>{subItem.title}</Link>
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      </Collapse>
                    </>
                  ) : (
                    // Simple menu item without submenu
                    <Link
                      to={item.to || "#"}
                      onClick={() => {
                        handleMenuActive(item.title);
                      }}
                    >
                      {item.iconStyle}
                      <span className="nav-text">{item.title}</span>
                    </Link>
                  )}
                </li>
              );
            })}
          </ul>
        </PerfectScrollbar>
      </div>
    </>
  );
};

export default SideBar;
