import React, { useState, useEffect } from "react";
import { Card, Col, Table, Row, Nav, Tab, Button, Dropdown } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import { Bar, Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import toast from "react-hot-toast";
import XGourdLoader from "../components/XGourdLoader";

// Register ChartJS components
ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title);

// Remove dependencies that caused issues
// Using conditional rendering instead of importing problematic libraries

// Dummy data - replace with API calls
const guardianData = [
  { name: "John Smith", type: "Personal", totalEarnings: 5240, bookings: 12, state: "California", city: "Los Angeles" },
  { name: "Guardian Agency LLC", type: "Agency", totalEarnings: 15680, bookings: 32, state: "New York", city: "New York" },
  { name: "Sarah Williams", type: "Personal", totalEarnings: 2100, bookings: 7, state: "Texas", city: "Austin" },
  { name: "Elite Security Co.", type: "Agency", totalEarnings: 22450, bookings: 45, state: "Florida", city: "Miami" },
  { name: "Michael Johnson", type: "Personal", totalEarnings: 3650, bookings: 9, state: "Nevada", city: "Las Vegas" },
];

const civilianData = [
  { name: "Robert Brown", totalSpending: 2450, bookings: 6, state: "California", city: "San Francisco" },
  { name: "Jennifer Davis", totalSpending: 5680, bookings: 12, state: "New York", city: "Brooklyn" },
  { name: "William Miller", totalSpending: 1200, bookings: 3, state: "Texas", city: "Houston" },
  { name: "Elizabeth Taylor", totalSpending: 4300, bookings: 8, state: "Florida", city: "Orlando" },
  { name: "James Anderson", totalSpending: 3100, bookings: 7, state: "Georgia", city: "Atlanta" },
];

// Location data for chart
const locationData = {
  labels: ['California', 'New York', 'Texas', 'Florida', 'Nevada', 'Georgia'],
  datasets: [
    {
      label: 'Bookings by Location',
      data: [18, 44, 10, 53, 9, 7],
      backgroundColor: [
        'rgba(255, 99, 132, 0.7)',
        'rgba(54, 162, 235, 0.7)',
        'rgba(255, 206, 86, 0.7)',
        'rgba(75, 192, 192, 0.7)',
        'rgba(153, 102, 255, 0.7)',
        'rgba(255, 159, 64, 0.7)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

// Guardian type data for chart
const guardianTypeData = {
  labels: ['Personal', 'Agency'],
  datasets: [
    {
      label: 'Guardian Types',
      data: [28, 77],
      backgroundColor: [
        'rgba(54, 162, 235, 0.7)',
        'rgba(75, 192, 192, 0.7)',
      ],
      borderColor: [
        'rgba(54, 162, 235, 1)',
        'rgba(75, 192, 192, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

export default function MarketingAnalytics() {
  const [loader, setLoader] = useState(true);
  const [guardianSort, setGuardianSort] = useState({ field: 'totalEarnings', direction: 'desc' });
  const [civilianSort, setCivilianSort] = useState({ field: 'totalSpending', direction: 'desc' });
  const [filteredGuardianData, setFilteredGuardianData] = useState([]);
  const [filteredCivilianData, setFilteredCivilianData] = useState([]);
  const [guardianFilter, setGuardianFilter] = useState({ type: '', location: '' });
  const [civilianFilter, setCivilianFilter] = useState({ location: '' });

  // Simulate data fetching
  useEffect(() => {
    setTimeout(() => {
      setFilteredGuardianData(guardianData);
      setFilteredCivilianData(civilianData);
      setLoader(false);
    }, 1000);
  }, []);

  // Apply filters and sorting to guardian data
  useEffect(() => {
    // Guardian data filtering and sorting
    let filtered = [...guardianData];
    
    // Apply filters
    if (guardianFilter.type) {
      filtered = filtered.filter(item => item.type === guardianFilter.type);
    }
    
    if (guardianFilter.location) {
      filtered = filtered.filter(item => 
        item.state.toLowerCase().includes(guardianFilter.location.toLowerCase()) ||
        item.city.toLowerCase().includes(guardianFilter.location.toLowerCase())
      );
    }
    
    // Apply sorting
    filtered.sort((a, b) => {
      const aValue = a[guardianSort.field];
      const bValue = b[guardianSort.field];
      
      if (guardianSort.direction === 'asc') {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });
    
    setFilteredGuardianData(filtered);
  }, [guardianFilter, guardianSort]);

  // Filter and sort civilian data
  useEffect(() => {
    // Civilian data filtering and sorting
    let filtered = [...civilianData];
    
    // Apply location filter
    if (civilianFilter.location) {
      filtered = filtered.filter(item => 
        item.state.toLowerCase().includes(civilianFilter.location.toLowerCase()) ||
        item.city.toLowerCase().includes(civilianFilter.location.toLowerCase())
      );
    }
    
    // Apply sorting
    filtered.sort((a, b) => {
      const aValue = a[civilianSort.field];
      const bValue = b[civilianSort.field];
      
      if (civilianSort.direction === 'asc') {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });
    
    setFilteredCivilianData(filtered);
  }, [civilianFilter, civilianSort]);

  // Handle sort for guardian data
  const handleGuardianSort = (field) => {
    setGuardianSort(prev => ({
      field,
      direction: prev.field === field && prev.direction === 'desc' ? 'asc' : 'desc'
    }));
  };

  // Handle sort for civilian data
  const handleCivilianSort = (field) => {
    setCivilianSort(prev => ({
      field,
      direction: prev.field === field && prev.direction === 'desc' ? 'asc' : 'desc'
    }));
  };

  // Export functions simplified to avoid dependency issues
  const exportData = (type) => {
    toast.success(`${type} data exported successfully! (This is a mock export)`);
  };

  return (
    <>
      <PageTitle activeMenu="Marketing Analytics" motherMenu="Admin" />
      
      {loader ? (
        <div className="text-center mt-5">
          <XGourdLoader />
        </div>
      ) : (
        <>
          <Tab.Container defaultActiveKey="guardian">
            <Card>
              <Card.Header>
                <Nav variant="tabs" className="nav-tabs-card">
                  <Nav.Item>
                    <Nav.Link eventKey="guardian">Guardian & Agency Analytics</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="civilian">Civilian Analytics</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="charts">Visual Analytics</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Card.Header>
              <Tab.Content>
                {/* Guardian & Agency Analytics Tab */}
                <Tab.Pane eventKey="guardian">
                  <Card.Body>
                    <Row className="mb-4">
                      <Col sm={12} md={6} lg={4}>
                        <div className="mb-3">
                          <label className="form-label">Filter by Type:</label>
                          <select 
                            className="form-control"
                            value={guardianFilter.type}
                            onChange={(e) => setGuardianFilter({...guardianFilter, type: e.target.value})}
                          >
                            <option value="">All Types</option>
                            <option value="Personal">Personal</option>
                            <option value="Agency">Agency</option>
                          </select>
                        </div>
                      </Col>
                      <Col sm={12} md={6} lg={4}>
                        <div className="mb-3">
                          <label className="form-label">Filter by Location:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="State or City"
                            value={guardianFilter.location}
                            onChange={(e) => setGuardianFilter({...guardianFilter, location: e.target.value})}
                          />
                        </div>
                      </Col>
                      <Col sm={12} md={12} lg={4} className="d-flex align-items-end mb-3">
                        <div className="export-buttons ml-auto d-flex gap-2">
                          <Button variant="primary" onClick={() => exportData('Guardian CSV')}>
                            Export CSV
                          </Button>
                          <Button variant="success" onClick={() => exportData('Guardian PDF')}>
                            Export PDF
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    
                    <div className="table-responsive">
                      <Table className="table-striped">
                        <thead>
                          <tr>
                            <th onClick={() => handleGuardianSort('name')} style={{cursor: 'pointer'}}>
                              Guardian/Agency Name {guardianSort.field === 'name' && (guardianSort.direction === 'asc' ? '↑' : '↓')}
                            </th>
                            <th onClick={() => handleGuardianSort('type')} style={{cursor: 'pointer'}}>
                              Type {guardianSort.field === 'type' && (guardianSort.direction === 'asc' ? '↑' : '↓')}
                            </th>
                            <th onClick={() => handleGuardianSort('totalEarnings')} style={{cursor: 'pointer'}}>
                              Total Earnings {guardianSort.field === 'totalEarnings' && (guardianSort.direction === 'asc' ? '↑' : '↓')}
                            </th>
                            <th onClick={() => handleGuardianSort('bookings')} style={{cursor: 'pointer'}}>
                              Bookings {guardianSort.field === 'bookings' && (guardianSort.direction === 'asc' ? '↑' : '↓')}
                            </th>
                            <th onClick={() => handleGuardianSort('state')} style={{cursor: 'pointer'}}>
                              Location {guardianSort.field === 'state' && (guardianSort.direction === 'asc' ? '↑' : '↓')}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredGuardianData.map((item, index) => (
                            <tr key={index}>
                              <td>{item.name}</td>
                              <td>
                                <span className={`badge ${item.type === 'Agency' ? 'bg-primary' : 'bg-info'}`}>
                                  {item.type}
                                </span>
                                {item.bookings > 10 && (
                                  <span className="badge bg-success ml-2 ms-2">Frequently Booked</span>
                                )}
                              </td>
                              <td>${item.totalEarnings.toLocaleString()}</td>
                              <td>{item.bookings}</td>
                              <td>{item.city}, {item.state}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </Card.Body>
                </Tab.Pane>
                
                {/* Civilian Analytics Tab */}
                <Tab.Pane eventKey="civilian">
                  <Card.Body>
                    <Row className="mb-4">
                      <Col sm={12} md={6} lg={4}>
                        <div className="mb-3">
                          <label className="form-label">Filter by Location:</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="State or City"
                            value={civilianFilter.location}
                            onChange={(e) => setCivilianFilter({...civilianFilter, location: e.target.value})}
                          />
                        </div>
                      </Col>
                      <Col sm={12} md={12} lg={8} className="d-flex align-items-end mb-3">
                        <div className="export-buttons ml-auto d-flex gap-2">
                          <Button variant="primary" onClick={() => exportData('Civilian CSV')}>
                            Export CSV
                          </Button>
                          <Button variant="success" onClick={() => exportData('Civilian PDF')}>
                            Export PDF
                          </Button>
                        </div>
                      </Col>
                    </Row>
                    
                    <div className="table-responsive">
                      <Table className="table-striped">
                        <thead>
                          <tr>
                            <th onClick={() => handleCivilianSort('name')} style={{cursor: 'pointer'}}>
                              Civilian Name {civilianSort.field === 'name' && (civilianSort.direction === 'asc' ? '↑' : '↓')}
                            </th>
                            <th onClick={() => handleCivilianSort('totalSpending')} style={{cursor: 'pointer'}}>
                              Total Spending {civilianSort.field === 'totalSpending' && (civilianSort.direction === 'asc' ? '↑' : '↓')}
                            </th>
                            <th onClick={() => handleCivilianSort('bookings')} style={{cursor: 'pointer'}}>
                              Bookings {civilianSort.field === 'bookings' && (civilianSort.direction === 'asc' ? '↑' : '↓')}
                            </th>
                            <th onClick={() => handleCivilianSort('state')} style={{cursor: 'pointer'}}>
                              Location {civilianSort.field === 'state' && (civilianSort.direction === 'asc' ? '↑' : '↓')}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredCivilianData.map((item, index) => (
                            <tr key={index}>
                              <td>{item.name}</td>
                              <td>${item.totalSpending.toLocaleString()}</td>
                              <td>{item.bookings}</td>
                              <td>{item.city}, {item.state}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </Card.Body>
                </Tab.Pane>
                
                {/* Visual Analytics Tab */}
                <Tab.Pane eventKey="charts">
                  <Card.Body>
                    <Row>
                      <Col md={6}>
                        <Card className="h-100">
                          <Card.Header>
                            <h4 className="card-title">Bookings by Location</h4>
                          </Card.Header>
                          <Card.Body>
                            <div style={{ height: '300px' }}>
                              <Bar
                                data={locationData}
                                options={{
                                  responsive: true,
                                  maintainAspectRatio: false,
                                  plugins: {
                                    legend: {
                                      position: 'top',
                                    },
                                    title: {
                                      display: false,
                                    },
                                  },
                                }}
                              />
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                      
                      <Col md={6}>
                        <Card className="h-100">
                          <Card.Header>
                            <h4 className="card-title">Guardian Types</h4>
                          </Card.Header>
                          <Card.Body>
                            <div style={{ height: '300px' }}>
                              <Pie
                                data={guardianTypeData}
                                options={{
                                  responsive: true,
                                  maintainAspectRatio: false,
                                }}
                              />
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                    
                    <Row className="mt-4">
                      <Col md={12}>
                        <Card>
                          <Card.Header>
                            <h4 className="card-title">Key Insights</h4>
                          </Card.Header>
                          <Card.Body>
                            <ul className="list-group">
                              <li className="list-group-item d-flex justify-content-between align-items-center">
                                Most Active State
                                <span className="badge bg-primary rounded-pill">Florida</span>
                              </li>
                              <li className="list-group-item d-flex justify-content-between align-items-center">
                                Most Popular Guardian Type
                                <span className="badge bg-success rounded-pill">Agency</span>
                              </li>
                              <li className="list-group-item d-flex justify-content-between align-items-center">
                                Average Bookings per Customer
                                <span className="badge bg-info rounded-pill">7.2</span>
                              </li>
                              <li className="list-group-item d-flex justify-content-between align-items-center">
                                Top Earning Guardian
                                <span className="badge bg-warning rounded-pill">Elite Security Co.</span>
                              </li>
                              <li className="list-group-item d-flex justify-content-between align-items-center">
                                Highest Spending Civilian
                                <span className="badge bg-danger rounded-pill">Jennifer Davis</span>
                              </li>
                            </ul>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Card.Body>
                </Tab.Pane>
              </Tab.Content>
            </Card>
          </Tab.Container>
        </>
      )}
    </>
  );
} 