import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../images/logo-text.png";

const ForgotPassword = () => {
   const navigate = useNavigate();
   const [email, setEmail] = useState("");
   const [error, setError] = useState("");
   const [success, setSuccess] = useState("");
   
   const onSubmit = (e) => {
      e.preventDefault();
      
      if (!email) {
        setError("Please enter your email address");
        return;
      }
      
      // Simulate password reset request
      setSuccess("Password reset instructions have been sent to your email.");
      setTimeout(() => {
        navigate("/page-login");
      }, 3000);
   };
   
   return (
      <div className="authincation h-100 p-meddle">
         <div className="container h-100">
            <div className="row justify-content-center h-100 align-items-center">
               <div className="col-md-6">
                  <div className="authincation-content">
                     <div className="row no-gutters">
                        <div className="col-xl-12">
                           <div className="auth-form">
                              <div className="text-center mb-3">
                                 <img src={logo} alt="XGuard Logo" style={{ width: '150px' }} />
                              </div>
                              <h4 className="text-center mb-4">
                                 Forgot Password
                              </h4>
                              {error && (
                                <div className="alert alert-danger">
                                  {error}
                                </div>
                              )}
                              {success && (
                                <div className="alert alert-success">
                                  {success}
                                </div>
                              )}
                              <form onSubmit={onSubmit}>
                                 <div className="form-group">
                                    <label>
                                       <strong>Email</strong>
                                    </label>
                                    <input
                                       type="email"
                                       className="form-control"
                                       value={email}
                                       onChange={(e) => setEmail(e.target.value)}
                                       placeholder="Enter your email"
                                    />
                                 </div>
                                 <div className="text-center">
                                    <button
                                       type="submit"
                                       className="btn btn-primary btn-block"
                                    >
                                       SUBMIT
                                    </button>
                                 </div>
                              </form>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default ForgotPassword;
