import React from "react";

export default function Filter({
  setSearch,
  searchType,
  filterType,
  setFilterType,
  FilterOption,
  filter,
  tabs,
  filterTitle,
}) {
  return (
    <div
      className="d-flex align-items-end justify-content-between "
      style={{ flexWrap: "wrap" }}
    >
      {searchType === "search" ? (
        <div className="input-group ">
          <input
            type="text"
            id="search-box"
            className="form-control"
            placeholder="Search"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      ) : (
        <div></div>
      )}

      {filter === "filter" && (
        <div className="d-flex align-items-center">
          <select
            className="form-control btn"
            onChange={(e) => setFilterType(e.target.value)}
            value={filterType}
          >
            <option value="" className="filter-options" disabled>
              {filterTitle}
            </option>
            {FilterOption?.map((item, index) => (
              <option className="filter-options" value={item?.value} key={`filter-option-${index}-${item?.value || ''}`}>
                {item?.label}
              </option>
            ))}
          </select>
        </div>
      )}
      {tabs && (
        <div className="filter-tabs">
          {FilterOption.map((option, index) => (
            <p
              onClick={() => setFilterType(option.value)}
              className={`${
                option.value === filterType ? "active-tab" : "tab"
              }`}
              key={`tab-option-${index}-${option.value || ''}`}
            >
              {option.label}
            </p>
          ))}
        </div>
      )}
    </div>
  );
}
